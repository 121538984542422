import React, { useContext } from 'react';

import { TulaContext } from '../../contexts/TulaContext';

const HoursHistory = () => {
  const { state } = useContext(TulaContext);

  const renderList = () => {
    return state.hours.map((hour, i) => {
      return (
        <div key={hour.id}>
          <p>{hour.fields.formattedDate}</p>
          <p>
            {hour.fields.type === 'withdraw'
              ? hour.fields.relatedTodoName[0]
              : hour.fields.source}
          </p>
          <p>{hour.fields.hours} hours</p>
        </div>
      );
    });
  };
  return (
    <div className="hours-history">
      <p className="mini-title">History</p>
      <div className="scrollable-history">{renderList()}</div>
    </div>
  );
};

export default HoursHistory;
