import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useLocation } from 'react-router-dom';
import { createRecord } from '../../utils/queries';
function getMarketId() {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    market: searchParams.get('market') || searchParams.get('Market'),
  };
}
const SignUp = ({
  updateErrorMessage,
  updateConfirmationMessage,
  switchState,
  setSignUpEmail,
}) => {
  const location = useLocation();
  const [showConfirmation, setShowConfirmation] = useState(
    location.pathname === '/confirm',
  );
  const[marketPlaceOptions]=useState(["Denver/Boulder","Austin","Virtual Assistance Only"]);

  const [formValues, setFormValues] = useState({
    username: '',
    password: '',
    email: '',
    market: '',
    authCode: '',
  });

  useEffect(() => {
    setShowConfirmation(location.pathname === '/confirm');
    const params = getMarketId(window.location.href);
    setFormValues({
      username: '',
      password: '',
      email: '',
      market: params.market,
      authCode: '',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
 
  const onChange = (key, value) => {
    setFormValues((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  const signUp = async () => {
    try {
      const emailAddress = formValues.username.toLowerCase();
      
      if(!formValues.market){
        //window.alert('Please select a market');
        updateErrorMessage('Please select a market');
      }else{
        await Auth.signUp({
          username: emailAddress,
          password: formValues.password,
          attributes: {
            email: emailAddress,
           'custom:airtable_user_id': '0',
          },
        });
  
        setShowConfirmation(true);
        updateErrorMessage(null);
      }
      
    } catch (err) {
      console.log('error signing up: ', err);
      updateErrorMessage(err.message);
    }
  };

  const confirmSignUp = async () => {
    try {
      const emailAddress = formValues.username.toLowerCase();
      await Auth.confirmSignUp(emailAddress, formValues.authCode);
      updateConfirmationMessage('Successful Confirmation. Please Sign In');

      const newCustomer = {
        email: emailAddress,
        market: formValues.market,
      };
      await createRecord('customers', newCustomer);

      updateErrorMessage(null);
      setSignUpEmail(emailAddress);
      switchState('showSignIn');
    } catch (err) {
      console.log('error confirming signing up: ', err);
      updateErrorMessage(err.message);
    }
  };

  return (
    <div className="signup-auth">
      {!showConfirmation && (
        <>
          <p className="hand-font">Welcome!</p>
          <div className="signup-input-container">
            <div className="input-blocks">
              <i className="far fa-envelope"></i> 
              <input
                placeholder=" Enter Your E-mail"
                onChange={(evt) => onChange('username', evt.target.value)}
              />
            </div>
            <div className="input-blocks">
               <i className="fas fa-key"></i>
              <input
                placeholder=" Enter Your Password"
                type="password"
                onChange={(evt) => onChange('password', evt.target.value)}
              />
            </div>

            <div className="input-blocks">
              <i className="fa fa-map-marker"></i>
              <select
                placeholder="Select Your Market"
                value={'market'}
                onChange={(evt) => onChange('market', evt.target.value)}
              >
                {formValues.market && (
                  <option value={formValues.market} selected>
                    {formValues.market}
                  </option>
                )}
                <option value="" hidden selected>
                  Select Your Market
                </option>
                {marketPlaceOptions.filter(m=>m!==formValues.market).map((mplace,index)=>(<option value={mplace} key={index} >{mplace}</option>))}
                
              </select>
             
            </div>
            <p className="signup-input-container-privacy">
              By joining, I agree to TULA’s{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.tulabalanced.com/privacy"
              >
                Terms and Privacy Policy
              </a>
            </p>

            <p className="signup-input-container-privacy">
              Once you sign up we will send you a secret code in you email to
              confirm your identity.
            </p>
            <p className="signup-input-container-privacy">
              <Link to="/confirm">I already have a secret code.</Link>
            </p>
         
           </div>
                
          <div className="button-container">
            <button className="bg-black" onClick={signUp}>
              Create Account
            </button>
          </div>
      </>
      )}
      {showConfirmation && (
        <>
          <div className="confirm-container">
            <p>
              We sent a confirmation code to your email – go check and return
              here.
            </p>
            <div className="input-blocks">
              <i className="far fa-envelope"></i>
              <input
                placeholder="Email"
                onChange={(evt) => onChange('username', evt.target.value)}
                defaultValue={formValues.username}
              />
            </div>
            <div className="input-blocks">
              <i className="fas fa-user-shield"></i>
              <input
                onChange={(evt) => onChange('authCode', evt.target.value)}
                placeholder="Confirmation Code"
              />
            </div>
          </div>
          <div className="button-container">
            <button className="bg-black" onClick={confirmSignUp}>
              Confirm Email
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SignUp;
