import React, { useContext, useEffect, useState } from 'react';

import HoursSummary from '../components/Hours/HoursSummary';
import TodoHub from '../components/Todos/TodoHub';
import WelcomeNotification from '../components/Shared/WelcomeNotification';
import Header from '../components/Shared/Header';
import { TulaContext } from '../contexts/TulaContext';
import { useHistory, useLocation } from 'react-router-dom';
import { getCustomerData } from '../utils/queries';
import Loader from '../components/Shared/Loader';

const Dash = () => {
  const { state, dispatch } = useContext(TulaContext);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!state.customer.fields.termsAgreement) {
      history.push('/create-profile');
    }
  }, []);

  useEffect(() => {
    const refreshData = async () => {
      setLoading(true);
      const customerData = await getCustomerData(state.customer.id);
      dispatch({ type: 'updateCustomerData', payload: { ...customerData } });
      setLoading(false);
    };

    if (location.search.split('refresh=')[1] === 'true') {
      refreshData();
    }
  }, [location]);

  return (
    <div className="dash">
      <header>
        <Header />
      </header>
      <section id="dash-section">
        {loading ? (
          <Loader />
        ) : (
          <>
            <HoursSummary />
            <TodoHub />
            <WelcomeNotification />
          </>
        )}
      </section>
    </div>
  );
};

export default Dash;
