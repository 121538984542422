import React, { useState, useEffect, createContext, useContext } from 'react';
import { Auth, Hub } from 'aws-amplify';

import { getCustomerData } from '../utils/queries';
import { TulaContext } from './TulaContext';

export const CurrentUserContext = createContext();

const AuthContext = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({ isloaded: false });
  const { dispatch } = useContext(TulaContext);

  useEffect(() => {
    const initCurrentUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = await Auth.currentUserInfo();
        const realuser = {
          ...user,
          ...{ attributes: attributes.attributes },
          isLoaded: true,
        };

        if (attributes.attributes['custom:airtable_user_id'] !== '0') {
          const customerData = await getCustomerData(
            attributes.attributes['custom:airtable_user_id'],
          );

          dispatch({ type: 'authIn', payload: { ...customerData } });

          setCurrentUser({
            ...realuser,
            ...customerData.customer,
          });
        } else {
          setCurrentUser({
            ...realuser,
          });
        }
      } catch (err) {
        console.log(err);
        setCurrentUser({ isLoaded: true });
      }
    };

    initCurrentUser();

    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          initCurrentUser();
          break;
        case 'signOut':
          setCurrentUser({ isLoaded: true });
          break;
        default:
          break;
      }
    });
  }, []);

  return (
    <CurrentUserContext.Provider value={[currentUser, setCurrentUser]}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default AuthContext;
