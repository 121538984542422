import React, { useContext } from 'react';

import Breadcrumb from '../components/Shared/Breadcrumb';
import { TulaContext } from '../contexts/TulaContext';

const QuestionForm = () => {
  const { state } = useContext(TulaContext);
  return (
    <div>
      <Breadcrumb path={{ returnRoute: '/get-hours', name: 'Get Hours' }} />
      <iframe
        title="contactUs"
        className="airtable-embed"
        src={`https://airtable.com/embed/shrj1HzELM1bE77Tl?prefill_email=${state.customer.fields.email}`}
        frameBorder="0"
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: '75px',
        }}
      ></iframe>
    </div>
  );
};

export default QuestionForm;
