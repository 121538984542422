import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TulaContext } from '../contexts/TulaContext';
import Breadcrumb from '../components/Shared/Breadcrumb';
import HourTypeSelect from '../components/Hours/HourTypeSelect';
import HourAmountSelect from '../components/Hours/HourAmountSelect';
import HoursPayment from '../components/Hours/HoursPayment';
import HoursSuccess from '../components/Hours/HoursSuccess';
import CancelSubscription from '../components/Hours/CancelSubscription';
import { post } from '../utils/requests';
import { updateRecord } from '../utils/queries';

import {
  allSubscriptionData,
  allSingleHourPrices,
} from '../content/subscriptions';

const subscriptionData = allSubscriptionData[process.env.REACT_APP_AWS_STAGE];
const singleHourPrices = allSingleHourPrices[process.env.REACT_APP_AWS_STAGE];

const HoursAdd = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(TulaContext);
  const [activeSubscription, setActiveSubscription] = useState();
  const [hourPurchase, setHourPurchase] = useState({});
  const [customerCards, setCustomerCards] = useState();
  const [step, setStep] = useState('typeSelect');

  useEffect(() => {
    const subscription = state.subscriptions.find(
      (sub) => sub.fields.status === 'active',
    );
    setActiveSubscription(subscription);

    const price = subscription
      ? subscriptionData.find((sub) => {
          return sub.priceId === subscription.fields.stripePriceId;
        })
      : singleHourPrices;

    setHourPurchase({
      alaCarteRate: subscription ? subscription.fields.ratePerHour : 50,
      defaultSinglePriceId: price.singlePriceId,
      // eslint-disable-next-line
      modifying: subscription ? true : false,
    });

    const createStripeCustomer = async () => {
      const stripeCustomer = await post('/stripe/create-customer', {
        customer: {
          firstName: state.customer.fields.firstName[0],
          lastName: state.customer.fields.lastName[0],
          phone: state.customer.fields.phoneNumber[0],
          address: state.customer.fields.address[0],
          airtableCustomerId: state.customer.id,
          email: state.customer.fields.email,
        },
      });

      const customerRecord = await updateRecord('customers', {
        id: state.customer.id,
        fields: {
          stripeId: stripeCustomer.data.id,
        },
      });

      dispatch({ type: 'setCustomer', payload: customerRecord });
      getCustomerCards(stripeCustomer.data.id);
    };

    if (!state.customer.fields.stripeId) {
      createStripeCustomer();
    } else {
      getCustomerCards(state.customer.fields.stripeId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomerCards = async (customerId) => {
    const res = await post('/stripe/get-cards', {
      customerId,
    });
    setCustomerCards(res.data.data);
  };

  const handleBack = () => {
    switch (step) {
      case 'amountSelect': {
        return setStep('typeSelect');
      }
      case 'payment': {
        return setStep('amountSelect');
      }
      case 'confirm': {
        return setStep('payment');
      }
      default: {
        return setStep('typeSelect');
      }
    }
  };

  const stepTitle = () => {
    switch (step) {
      case 'payment': {
        return 'Payment';
      }
      case 'confirm': {
        return 'Confirm';
      }
      default: {
        return 'Get Hours';
      }
    }
  };

  console.log('hourPurchase', hourPurchase);

  return (
    <div className="hours-add">
      {step === 'typeSelect' ? (
        <Breadcrumb
          path={{
            returnRoute: '/my-tula',
            name: 'Get Hours',
          }}
        />
      ) : (
        <div className="breadcrumb">
          {step !== 'success' ? (
            <i className="fas fa-chevron-left" onClick={handleBack}></i>
          ) : null}

          <p>{stepTitle()}</p>

          {step === 'success' ? (
            <i
              className="fas fa-times"
              onClick={() => history.push('/my-tula')}
            ></i>
          ) : null}
        </div>
      )}

      {step === 'typeSelect' ? (
        <HourTypeSelect
          setHourPurchase={setHourPurchase}
          setStep={setStep}
          activeSubscription={activeSubscription}
        />
      ) : null}

      {step === 'amountSelect' ? (
        <HourAmountSelect
          setHourPurchase={setHourPurchase}
          hourPurchase={hourPurchase}
          setStep={setStep}
          activeSubscription={activeSubscription}
        />
      ) : null}

      {step === 'cancelSubscription' ? (
        <CancelSubscription
          activeSubscription={activeSubscription}
          setStep={setStep}
        />
      ) : null}

      {step === 'payment' ? (
        <HoursPayment
          setStep={setStep}
          hourPurchase={hourPurchase}
          setHourPurchase={setHourPurchase}
          activeSubscription={activeSubscription}
          customerCards={customerCards}
        />
      ) : null}

      {step === 'success' ? <HoursSuccess hourPurchase={hourPurchase} /> : null}
    </div>
  );
};

export default HoursAdd;
