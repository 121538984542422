import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumb from '../components/Shared/Breadcrumb';
import { TulaContext } from '../contexts/TulaContext';
import { getScheduleLinks } from '../utils/queries';

const TodoSchedule = () => {
  const { state } = useContext(TulaContext);
  const params = useParams();
  const [embedUrl, setEmbedUrl] = useState();

  useEffect(() => {
    const getLinks = async () => {
      const todo = state.todos.find((todo) => todo.id === params.todoId);
      const links = await getScheduleLinks();
      const linkMatch =
        links.find(
          (link) => link.fields.appointmentHours >= todo.fields.hoursAgreement,
        ) || links[links.length - 1];

      setEmbedUrl(linkMatch.fields.link);
    };

    if (state.todos) {
      getLinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //bring them back to dash with a refresh

  return (
    <div>
      <Breadcrumb
        path={{ returnRoute: `/my-tula?refresh=true`, name: 'Edit Todo' }}
      />
      {embedUrl ? (
        <>
          <iframe
            src={embedUrl}
            title="Schedule Appointment"
            width="100%"
            height="800"
            frameBorder="0"
          ></iframe>
          <script
            src="https://embed.acuityscheduling.com/js/embed.js"
            type="text/javascript"
          ></script>
        </>
      ) : null}
    </div>
  );
};

export default TodoSchedule;
