import React from 'react';
import Loader from '../Shared/Loader';

const TodoConfirmCancel = ({
  loading,
  cancelTodo,
  setStep,
  canDelete,
  canCancel,
}) => {
  return (
    <>
      <div className="todo-success">
        <h1>Are you sure?</h1>

        {canDelete ? (
          <p>Deleting this TO-DO will remove it from your dashboard forever.</p>
        ) : null}

        {canCancel ? (
          <p>Cancelling this pending TO-DO will remove it from our queue.</p>
        ) : null}
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="button-container">
          {canDelete ? (
            <button
              className="bg-black"
              onClick={() => cancelTodo('cancelled')}
            >
              Delete
            </button>
          ) : null}

          {canCancel ? (
            <button
              className="bg-black"
              onClick={() => cancelTodo('unsubmitted')}
            >
              Cancel
            </button>
          ) : null}
          <button className="bg-gold" onClick={() => setStep('addHome')}>
            Nevermind
          </button>
        </div>
      )}
    </>
  );
};

export default TodoConfirmCancel;
