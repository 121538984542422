import React, { useState, useContext } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import Switch from 'react-switch';

import { post } from '../../utils/requests';
import { TulaContext } from '../../contexts/TulaContext';
import { apiDateFormat } from '../../utils/helpers';
import HoursConfirm from './HoursConfirm';
import Loader from '../Shared/Loader';
import { createRecord, getCustomerData } from '../../utils/queries';

const HoursPayment = ({
  setStep,
  hourPurchase,
  setHourPurchase,
  activeSubscription,
  customerCards,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { state, dispatch } = useContext(TulaContext);
  const [postal, setPostal] = useState('');
  const [processing, setProcessing] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [useExistingCard, setUseExistingCard] = useState(
    customerCards && customerCards.length > 0,
  );
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorToDisplay, setErrorToDisplay] = useState('');
  const [saveCard, setSaveCard] = useState(true);

  const handlePaymentThatRequiresCustomerAction = ({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) => {
    if (subscription && subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)

            setErrorMessage(result.error);
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  };

  const handleRequiresPaymentMethod = ({
    subscription,
    paymentMethodId,
    priceId,
  }) => {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status,
      );
      setErrorToDisplay('Your card was declined.');
      throw new Error('Your card was declined.');
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  };

  const retryInvoiceWithNewPaymentMethod = ({ paymentMethodId, invoiceId }) => {
    return (
      fetch(`${process.env.REACT_APP_API_URL}/stripe/retry-invoice`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customerId: state.customer.fields.stripeId,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: hourPurchase.subscription.priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          console.log(error);
          // An error has happened. Display the failure to the user here.
          setProcessing(false);
          setErrorToDisplay(error && error.error && error.error.decline_code);
        })
    );
  };

  const onSubscriptionComplete = async (result) => {
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (result && !result.subscription) {
      const subscription = { id: result.invoice.subscription };
      result.subscription = subscription;
      localStorage.clear();
    }

    const newSubscription = {
      startDate: apiDateFormat(new Date()),
      hourAmount: +hourPurchase.subscription.hours,
      ratePerHour: +hourPurchase.subscription.perHour,
      monthlyBillAmount:
        hourPurchase.discountedAmount !== null
          ? +hourPurchase.discountedAmount
          : +hourPurchase.subscription.price,
      stripeSubscriptionId: result.subscription.id,
      stripePriceId: hourPurchase.subscription.priceId,
      relatedCustomer: [state.customer.id],
    };

    if (hourPurchase.promoCode) {
      newSubscription.promoCode = hourPurchase.promoCode.code;
    }
    const subscriptionRes = await createRecord(
      'subscriptions',
      newSubscription,
    );

    const newHour = {
      type: 'deposit',
      hours: +hourPurchase.subscription.hours,
      source: 'subscription',
      relatedSubscription: [subscriptionRes.data[0].id],
      relatedCustomer: [state.customer.id],
    };
    await createRecord('hours', newHour);
    // }

    // TODO manage changing - post launch?
    // https://stripe.com/docs/billing/subscriptions/fixed-price#change-price
    // api end point to change
    // cancel_at_period_end: false,
    // proration_behavior: none;
    // mark airtable cancel at period end
    // write new subscription with new start date

    // don't write new record, update existing - mark date modified
    // next charge should be for new amount and hopefully have hour value with
    // just changing won't do anything to current hour balance?
    // if going down - do they get the rest of the month to use the remaining hours?
    // if going up do they get hours right away?

    // if (hourPurchase.modifying) {
    //   const subscriptionUpdate = {
    //     id: activeSubscription.id,
    //     fields: {
    //       cancelDate: apiDateFormat(new Date()),
    //       cancelled: true,
    //       modified: true,
    //       modifiedSubscription: [subscriptionRes.data.records[0].id],
    //     },
    //   };

    //   await updateRecord('subscriptions', subscriptionUpdate);
    // }

    const customerData = await getCustomerData(state.customer.id);
    dispatch({ type: 'updateCustomerData', payload: { ...customerData } });
    setProcessing(false);
    setStep('success');

    // GO TO SUCCESS
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  };

  const createSubscription = ({ paymentMethodId }) => {
    return (
      fetch(`${process.env.REACT_APP_API_URL}/stripe/create-subscription`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customerId: state.customer.fields.stripeId,
          paymentMethodId: paymentMethodId,
          items: [{ price: hourPurchase.subscription.priceId }],
          promo: hourPurchase.promoCode ? hourPurchase.promoCode.id : null,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          console.log('result 1', result);
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result.error;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result,
            paymentMethodId: paymentMethod.id,
            priceId: hourPurchase.subscription.priceId,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setProcessing(false);
          setErrorToDisplay(error.message || error.error.decline_code);
        })
    );
  };

  const handleSubmit = async () => {
    // TODO: Wrap in try catch?
    if (!stripe || !elements) {
      return;
    }
    setErrorMessage(null);
    setErrorToDisplay('');
    setProcessing(true);

    if (hourPurchase.type === 'alaCarte') {
      console.log('hourPurchase in submit', hourPurchase);
      if (hourPurchase.discountedAmount === 0) {
        createHoursRecord();
      } else {
        const res = await post('/stripe/create-payment-intent', {
          item: hourPurchase,
          customer: state.customer.fields.stripeId,
          email: state.customer.fields.email,
        });
        const payload = await stripe.confirmCardPayment(res.data.clientSecret, {
          payment_method: paymentMethod.id,
          receipt_email: state.customer.fields.email,
        });

        if (payload.error) {
          setErrorMessage(payload.error);
          setProcessing(false);
        } else {
          createHoursRecord();
        }
      }
    } else {
      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        'latestInvoicePaymentIntentStatus',
      );
      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem('latestInvoiceId');

        retryInvoiceWithNewPaymentMethod({
          customerId: state.customer.fields.stripeId,
          paymentMethodId: paymentMethod.id,
          invoiceId,
          priceId: hourPurchase.subscription.priceId,
        });
      } else {
        createSubscription({
          paymentMethodId: paymentMethod.id,
        });
      }
    }
  };

  const createHoursRecord = async () => {
    const newHour = {
      type: 'deposit',
      hours: +hourPurchase.hours,
      source: 'alaCarte',
      relatedCustomer: [state.customer.id],
    };
    await createRecord('hours', newHour);
    const customerData = await getCustomerData(state.customer.id);
    dispatch({
      type: 'updateCustomerData',
      payload: { ...customerData },
    });
    setProcessing(false);
    setStep('success');
  };

  const handleNext = async (savedCard) => {
    if (savedCard) {
      setPaymentMethod(customerCards[0]);
      setConfirm(true);
    } else {
      const cardElement = elements.getElement(CardNumberElement);

      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            postal_code: postal,
          },
        },
      });

      if (payload.error) {
        setErrorMessage(payload.error);
      } else {
        setPaymentMethod(payload.paymentMethod);
        setConfirm(true);
      }
    }
  };

  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <>
          {!confirm ? (
            <>
              {useExistingCard ? (
                <>
                  <p className="payment-method">Select Payment Method</p>
                  <div className="saved-cc">
                    <label>Use Saved Credit Card</label>
                    <p>
                      {customerCards[0].card.brand.toUpperCase()} ending in{' '}
                      {customerCards[0].card.last4}
                    </p>
                  </div>

                  <div className="button-container">
                    <button
                      onClick={() => setUseExistingCard(false)}
                      className={`bg-white SubmitButton ${
                        errorMessage ? 'SubmitButton--error' : ''
                      }`}
                    >
                      ENTER NEW CREDIT CARD
                    </button>
                    <button
                      onClick={() => handleNext(true)}
                      className={`bg-black SubmitButton ${
                        errorMessage ? 'SubmitButton--error' : ''
                      }`}
                    >
                      NEXT STEP: CONFIRM
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="Form">
                    <fieldset className="stripe-form-container">
                      <input id="name" required placeholder="Name" />
                      <CardNumberElement id="cardNumber" />
                      <div className="card-info">
                        <CardExpiryElement id="expiry" />
                        <CardCvcElement id="cvc" />
                      </div>
                      <input
                        id="postal"
                        required
                        placeholder="Zip Code"
                        value={postal}
                        onChange={(e) => {
                          setPostal(e.target.value);
                        }}
                      />
                    </fieldset>

                    <div className="card-save">
                      <Switch
                        onChange={() => setSaveCard(!saveCard)}
                        checked={saveCard}
                        offColor="#e3e1df"
                        onColor="#dfc9a6"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={36}
                      />
                      <p>Save this card to your account?</p>
                    </div>

                    {errorMessage && (
                      <p className="card-error">{errorMessage.message}</p>
                    )}
                    {errorToDisplay && (
                      <p className="card-error">{errorToDisplay}</p>
                    )}
                  </div>
                  <div className="button-container">
                    {customerCards && customerCards.length > 0 ? (
                      <button
                        onClick={() => setUseExistingCard(true)}
                        className={`bg-white SubmitButton ${
                          errorMessage ? 'SubmitButton--error' : ''
                        }`}
                      >
                        USE SAVED CREDIT CARD
                      </button>
                    ) : null}
                    <button
                      onClick={() => handleNext(false)}
                      className={`bg-black SubmitButton ${
                        errorMessage ? 'SubmitButton--error' : ''
                      }`}
                    >
                      NEXT STEP: CONFIRM
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <HoursConfirm
              setConfirm={setConfirm}
              hourPurchase={hourPurchase}
              setHourPurchase={setHourPurchase}
              activeSubscription={activeSubscription}
              handleSubmit={handleSubmit}
            />
          )}
        </>
      )}
    </>
  );
};

export default HoursPayment;
