import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useLocation } from 'react-router-dom';

const ForgotPassword = ({
  updateErrorMessage,
  updateConfirmationMessage,
  switchState,
}) => {
  const location = useLocation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [directConfirm, setDirectConfirm] = useState(false);

  const [formValues, setFormValues] = useState({
    username: '',
    password: '',
    email: '',
    confirmationCode: '',
  });

  useEffect(() => {
    setShowConfirmation(location.pathname === '/forgot-confirm');
    setDirectConfirm(location.pathname === '/forgot-confirm');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const onChange = (key, value) => {
    updateErrorMessage(null);

    setFormValues((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  const forgotPassword = async () => {
    try {
      await Auth.forgotPassword(formValues.username.toLowerCase());
      setShowConfirmation(true);
    } catch (err) {
      console.log('error: ', err);
      updateErrorMessage(err.message);
    }
  };

  const forgotPasswordSubmit = async () => {
    try {
      const { username, password, confirmationCode } = formValues;
      await Auth.forgotPasswordSubmit(
        username.toLowerCase(),
        confirmationCode,
        password,
      );

      updateConfirmationMessage('Successful Reset. Please Sign In');
      switchState('showSignIn');
    } catch (err) {
      console.log('error resetting password:', err);
      updateErrorMessage(err.message);
    }
  };

  return (
    <div className="forgot-password-auth">
      {!showConfirmation && (
        <>
          <p>We will send a secret code to your email address.</p>
          <div className="forgot-password-container">
            <div className="input-blocks">
              <i className="far fa-envelope"></i>
              <input
                onChange={(evt) => onChange('username', evt.target.value)}
                placeholder="Email"
              />
            </div>
          </div>

          <p className="signup-input-container-privacy-forgot">
            <Link to="/forgot-confirm">I already have a secret code.</Link>
          </p>

          <div className="button-container">
            <button className="bg-black" onClick={forgotPassword}>
              Send Code
            </button>
          </div>
        </>
      )}
      {showConfirmation && (
        <div className="forgot-password-container">
          {directConfirm ? (
            <div className="input-blocks">
              <i className="far fa-envelope"></i>
              <input
                onChange={(evt) => onChange('username', evt.target.value)}
                placeholder="Email"
              />
            </div>
          ) : null}

          <div className="input-blocks">
            <i className="fas fa-user-secret"></i>
            <input
              onChange={(evt) => onChange('confirmationCode', evt.target.value)}
              placeholder="Confirmation Code"
            />
          </div>
          <div className="input-blocks">
            <i className="fas fa-key"></i>
            <input
              onChange={(evt) => onChange('password', evt.target.value)}
              type="password"
              placeholder="New Password"
            />
          </div>

          <div className="button-container">
            <button className="bg-black" onClick={forgotPasswordSubmit}>
              Reset Password
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
