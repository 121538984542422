import React from 'react';
import { todoNeedsAttention } from '../utils/helpers';

const TulaContext = React.createContext();

const initialState = {
  todos: [],
  hours: [],
  subscriptions: [],
  todoNotices: [],
  customer: null,
  profile: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'authIn':
    case 'updateCustomerData': {
      const todoNotices = action.payload.todos.filter((todo) =>
        todoNeedsAttention(todo.fields),
      );
      return {
        ...state,
        todos: action.payload.todos,
        hours: action.payload.hours,
        subscriptions: action.payload.subscriptions,
        profile: action.payload.profile,
        customer: action.payload.customer,
        todoNotices,
      };
    }
    case 'authOut': {
      return {
        ...state,
        todos: initialState.todos,
        hours: initialState.hours,
        subscriptions: initialState.subscriptions,
        profile: initialState.profile,
        customer: initialState.customer,
        todoNotices: initialState.todoNotices,
      };
    }
    case 'setTodos': {
      return { ...state, todos: action.payload };
    }
    case 'addTodo': {
      return { ...state, todos: [...state.todos, action.todo] };
    }
    case 'clearTodos': {
      return { ...state, todos: initialState.todos };
    }
    case 'setHours': {
      return { ...state, hours: action.payload };
    }
    case 'addHours': {
      return { ...state, hours: [...state.hours, action.todo] };
    }
    case 'clearHours': {
      return { ...state, hours: initialState.hours };
    }
    case 'setSubscriptions': {
      return { ...state, subscriptions: action.payload };
    }
    case 'addSubscriptions': {
      return { ...state, subscriptions: [...state.subscriptions, action.todo] };
    }
    case 'clearSubscriptions': {
      return { ...state, subscriptions: initialState.subscriptions };
    }
    case 'setCustomer': {
      return { ...state, customer: action.payload };
    }
    case 'clearCustomer': {
      return { ...state, customer: initialState.customer };
    }
    case 'setProfile': {
      return { ...state, profile: action.payload };
    }
    case 'clearProfile': {
      return { ...state, profile: initialState.profile };
    }
    default: {
      return initialState;
    }
  }
};

function TulaContextProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <TulaContext.Provider value={value}>{props.children}</TulaContext.Provider>
  );
}

const TulaContextConsumer = TulaContext.Consumer;

export { TulaContext, TulaContextProvider, TulaContextConsumer };
