import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { TulaContext } from '../../contexts/TulaContext';
import TodoList from './TodoList';

const TodoHub = () => {
  const { state } = useContext(TulaContext);

  return (
    <div className="todo-hub">
      <div className="new-todo">
        <p>TO-DOs</p>
        <span>
          {state.todos.length ? (
            <Link to="/new-todo">
              {' '}
              <i className="fas fa-plus-circle"></i> NEW TO-DO
            </Link>
          ) : null}
        </span>
      </div>

      <div className="new-todo-container">
        {state.todos.length ? (
          <TodoList />
        ) : state.customer.fields.hoursBalance ? (
          <div className="first-todo">
            <Link to="/new-todo">
              Add your first TO-DO
              <i className="fas fa-plus-circle"></i>
            </Link>
          </div>
        ) : (
          <p className="ui-message">
            <Link to="/get-hours">Get Hours to add TO-DOs</Link>
          </p>
        )}
      </div>
    </div>
  );
};

export default TodoHub;
