import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Storage } from 'aws-amplify';

import { CurrentUserContext } from '../contexts/AuthContext';
import { TulaContext } from '../contexts/TulaContext';
import { updateRecord, createRecord, getCustomerData } from '../utils/queries';
import { s3Url } from '../utils/helpers';
import Breadcrumb from '../components/Shared/Breadcrumb';
import ProfileForm from '../components/Profile/ProfileForm';
import Loader from '../components/Shared/Loader';
import { post } from '../utils/requests';

const ProfileEdit = () => {
  const history = useHistory();
  const location = useLocation();
  const [profile, setProfile] = useState();
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);
  const { state, dispatch } = useContext(TulaContext);
  const isNew = location.pathname === '/create-profile';

  useEffect(() => {
    if (state && state.profile) {
      setProfile(state.profile);
    } else {
      setProfile({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const updateProfile = async (fields, image) => {
    setLoading(true);
    try {
      if (image) {
        const res = await Storage.put(`${image.name}`, image, {
          contentType: image.type,
          ACL: 'public-read',
          visibility: 'public',
          level: 'public',
        });

        fields.pictureS3Url = s3Url(res.key);
        fields.picture = [{ url: s3Url(res.key), filename: res.key }];
      }

      if (isNew) {
        const newProfile = {
          ...fields,
          relatedCustomer: [currentUser.id],
        };

        const stripeCustomer = await post('/stripe/create-customer', {
          customer: {
            ...fields,
            airtableCustomerId: currentUser.id,
            email: state.customer.fields.email,
          },
        });

        const createRes = await createRecord('profile', newProfile);

        dispatch({ type: 'setProfile', payload: createRes.data[0] });

        const customerRecord = await updateRecord('customers', {
          id: currentUser.id,
          fields: {
            stripeId: stripeCustomer.data.id,
          },
        });

        setCurrentUser((prevState) => {
          return { ...prevState, ...customerRecord };
        });

        const customerData = await getCustomerData(state.customer.id);
        dispatch({ type: 'updateCustomerData', payload: { ...customerData } });
      } else {
        const profileUpdate = {
          id: profile.id,
          fields,
        };
        const updateRes = await updateRecord('profile', profileUpdate);

        dispatch({ type: 'setProfile', payload: updateRes.data[0] });
      }

      setSuccess('Your profile has been updated.');

      setLoading(false);

      if (isNew) {
        history.push('/my-tula');
      } else {
        history.push('/profile');
      }
    } catch (err) {
      setLoading(false);
      setError('There was an error updating your profile');
    }
  };

  return (
    <>
      {!profile ? (
        <Loader />
      ) : (
        <div className="profile-edit">
          <Breadcrumb
            path={{
              returnRoute: isNew ? '/create-profile' : '/profile',
              name: isNew ? 'Set Up Your Profile' : 'Your Profile',
            }}
          />

          <div className="profile-edit-form">
            {loading ? (
              <Loader />
            ) : (
              <>
                <ProfileForm
                  loading={loading}
                  isNew={isNew}
                  updateProfile={updateProfile}
                  profile={profile}
                />

                {success ? <p>{success}</p> : null}
                {error ? <p>{error}</p> : null}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileEdit;
