import React, { useContext, useEffect, useState } from 'react';
import { TulaContext } from '../../contexts/TulaContext';

const HoursForm = ({ setHourPurchase, hourPurchase }) => {
  const { state } = useContext(TulaContext);
  const [minimumPurchase, setMinimumPurchase] = useState(2);

  useEffect(() => {
    if (!hourPurchase.hours) {
      setHourPurchase((prevState) => {
        return { ...prevState, hours: 2 };
      });
    }

    if (state.subscriptions.find((sub) => !sub.fields.cancelled)) {
      setMinimumPurchase(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    e.persist();
    setHourPurchase((prevState) => {
      return { ...prevState, hours: e.target.value };
    });
  };

  const hoursStep = (amount) => {
    if (amount < 1 && hourPurchase.hours <= minimumPurchase) {
      return;
    }
    setHourPurchase((prevState) => {
      return {
        ...prevState,
        hours: prevState.hours ? +prevState.hours + amount : 0 + amount,
      };
    });
  };

  const disabledStep = () => {
    return hourPurchase.hours <= minimumPurchase;
  };

  return (
    <>
      <div className="hours-form">
        <label>How many hours would you like?</label>
        <div>
          {hourPurchase.hours ? (
            <>
              <input
                type="number"
                name="hours"
                value={hourPurchase.hours}
                onChange={handleChange}
                disabled={true}
              />
              <i
                className="fas fa-minus"
                style={disabledStep() ? { color: 'grey' } : null}
                onClick={() => hoursStep(-1)}
              ></i>
              <i className="fas fa-plus" onClick={() => hoursStep(1)}></i>
            </>
          ) : null}
        </div>
      </div>
      {minimumPurchase > 1 ? (
        <p className="ui-message hours">
          *Minimum of {minimumPurchase} can be purchased ala carte.
        </p>
      ) : null}
    </>
  );
};

export default HoursForm;
