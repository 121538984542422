import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { TulaContext } from '../../contexts/TulaContext';

const HoursSummary = () => {
  const { state } = useContext(TulaContext);

  return (
    <div className="hours-summary">
      <h4>TULA BALANCE</h4>
      <p>
        <span>{state.customer.fields.hoursBalance}</span>Hours
      </p>
      <Link to="/get-hours">
        <i className="fas fa-plus-circle"></i> GET HOURS
      </Link>
    </div>
  );
};

export default HoursSummary;
