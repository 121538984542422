import React, { useEffect } from 'react';

import Breadcrumb from '../components/Shared/Breadcrumb';

const RequestInvite = () => {
  useEffect(() => {
    window.fd('form', {
      formId: '5f0dec5ad832e40026fdd15a',
      containerEl: '#fd-form-5f0dec5ad832e40026fdd15a',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Breadcrumb path={{ returnRoute: '/', name: 'Request Invite' }} />
      <div id="fd-form-5f0dec5ad832e40026fdd15a"></div>
    </div>
  );
};

export default RequestInvite;
