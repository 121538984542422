import React, { useState } from 'react';

import SignIn from '../components/Auth/SignIn';
import SignUp from '../components/Auth/SignUp';
import ForgotPassword from '../components/Auth/ForgotPassword';
import Breadcrumb from '../components/Shared/Breadcrumb';
import { useLocation } from 'react-router-dom';
import FormMessage from '../components/Shared/FormMessage';

const Authenticator = (props) => {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState('');

  const authState = () => {
    switch (location.pathname) {
      case '/auth': {
        return 'showSignIn';
      }
      case '/sign-up':
      case '/invite':
      case '/confirm': {
        return 'showSignUp';
      }
      default: {
        return 'showSignIn';
      }
    }
  };
  const [currentState, setCurrentState] = useState(authState());

  const pageTitle = () => {
    switch (currentState) {
      case 'showSignIn': {
        return 'Sign In';
      }
      case 'showSignUp': {
        return 'Create Account';
      }
      case 'showForgotPassword': {
        return 'Reset Password';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <div className="authenticator">
      <Breadcrumb path={{ returnRoute: '/', name: pageTitle() }} />

      {currentState === 'showSignIn' && (
        <>
          <SignIn
            {...props}
            updateErrorMessage={setErrorMessage}
            updateConfirmationMessage={setConfirmationMessage}
            signUpEmail={signUpEmail}
          />
          <p
            id="forgot-password-text"
            onClick={() => setCurrentState('showForgotPassword')}
          >
            I forgot my password
          </p>

          <p
            id="forgot-password-text"
            onClick={() => setCurrentState('showSignUp')}
          >
            Create an account
          </p>
        </>
      )}

      {currentState === 'showSignUp' && (
        <SignUp
          {...props}
          updateErrorMessage={setErrorMessage}
          updateConfirmationMessage={setConfirmationMessage}
          switchState={setCurrentState}
          setSignUpEmail={setSignUpEmail}
        />
      )}

      {currentState === 'showForgotPassword' && (
        <ForgotPassword
          {...props}
          updateErrorMessage={setErrorMessage}
          updateConfirmationMessage={setConfirmationMessage}
          switchState={setCurrentState}
        />
      )}

      {confirmationMessage && (
        <FormMessage
          message={confirmationMessage}
          closeMessage={setConfirmationMessage}
        />
      )}
      {errorMessage && (
        <FormMessage message={errorMessage} closeMessage={setErrorMessage} />
      )}
    </div>
  );
};

export default Authenticator;
