import React, { useEffect, useState } from 'react';

import closeIcon from '../../assets/hamburger__close__icon.png';

const WelcomeNotification = () => {
  const [viewed, setViewed] = useState(true);

  useEffect(() => {
    const storageViewed = window.localStorage.getItem('welcomeNotification');
    setViewed(storageViewed !== null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeMessage = () => {
    setViewed(true);
    window.localStorage.setItem('welcomeNotification', true);
  };

  return (
    <>
      {!viewed ? (
        <div className="welcome-notification">
          <div>
            <p id="title">Welcome to TULA!</p>
            <img
              src={closeIcon}
              id="close-icon"
              alt="close"
              onClick={() => closeMessage()}
            />
          </div>

          <p id="description">
            This is your dashboard, where you can set up your TO-DOs and get
            hours so we cross things off your list.
          </p>
          <p>
            Have questions?{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tulabalanced.com/how-it-works#faq"
            >
              Read the FAQ
            </a>
          </p>
        </div>
      ) : null}
    </>
  );
};

export default WelcomeNotification;
