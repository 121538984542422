import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import config from './config';
import App from './App';

import './index.scss';

Amplify.configure(config[process.env.REACT_APP_AWS_STAGE]);

ReactDOM.render(<App />, document.getElementById('root'));
