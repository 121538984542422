import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Breadcrumb from '../components/Shared/Breadcrumb';
import { TulaContext } from '../contexts/TulaContext';
import HoursHistory from '../components/Hours/HoursHistory';

import patternBackground from '../assets/pattern__background.png';

const Hours = () => {
  const { state } = useContext(TulaContext);

  return (
    <>
      <Breadcrumb
        path={{
          returnRoute: '/my-tula',
          name: 'Tula Balanced',
        }}
      />
      <div className="hours">
        <img src={patternBackground} id="pattern_background" alt="pattern" />
        <div className="hours-info">
          <p id="hours-text">
            <span>{state.customer.fields.hoursBalance}</span> Hours
          </p>
          <p id="purchased-text">
            / {state.customer.fields.totalHoursPurchased} purchased
          </p>
        </div>
        <hr />
        <Link to="/get-hours">Get Hours</Link>
        <hr />
        <HoursHistory />
      </div>
    </>
  );
};

export default Hours;
