import React from 'react';
import { Link } from 'react-router-dom';

import successIcon from '../../assets/success__image.png';
import transBack from '../../assets/Tula_Pattern_10x10_LightGoldTransBack.png';

const TodoSuccess = ({ isSending }) => {
  isSending = true;
  return (
    <>
      <div className="todo-success">
        <img src={transBack} alt="pattern" id="pattern-background" />
        {isSending ? (
          <>
            <img src={successIcon} alt="success" />
            <p>TO-DO SENT!</p>
            <p>GREAT! We&apos;re on it. Keep an eye out for updates soon!</p>
          </>
        ) : (
          <>
            <p>TO-DO SAVED!</p>
            <p>
              You can continue to make updates to this TO-DO until to send it to
              TULA.
            </p>
          </>
        )}
      </div>

      <div className="button-container">
        <Link className="bg-black" to="/my-tula">
          GO TO DASH
        </Link>
      </div>
    </>
  );
};

export default TodoSuccess;
