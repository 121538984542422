import React from 'react';
import { useHistory } from 'react-router-dom';

import transBack from '../../assets/Tula_Pattern_10x10_LightGoldTransBack.png';

const HoursSuccess = ({ hourPurchase }) => {
  const history = useHistory();

  return (
    <>
      <div className="hours-success">
        <img src={transBack} alt="pattern" id="pattern-background" />
        <h1>Success!</h1>
        <p>
          {hourPurchase.type === 'subscription'
            ? hourPurchase.subscription.hours
            : hourPurchase.hours}{' '}
          hours have been added to your balance
        </p>
      </div>
      <div className="button-container">
        <button className="bg-black" onClick={() => history.push('/my-tula')}>
          GO TO DASH
        </button>
      </div>
    </>
  );
};

export default HoursSuccess;
