import React from 'react';

const HourTypeSelect = ({ setHourPurchase, setStep, activeSubscription }) => {
  const handleSelect = (type) => {
    setHourPurchase((prevState) => {
      return { ...prevState, type };
    });
    setStep('amountSelect');
  };

  const handleManage = () => {
    handleSelect('subscription');
    setHourPurchase((prevState) => {
      return { ...prevState, type: 'subscription', modifying: true };
    });
  };
  return (
    <>
      {activeSubscription ? (
        <div className="active-subscription">
          {activeSubscription.fields.cancelled ? (
            <p className="subs-info-text">
              Your current subscription has been cancelled and will end on{' '}
              {activeSubscription.fields.subscriptionEndDate}.
            </p>
          ) : (
            <p className="subs-info-text">
              You are subscribed to receive{' '}
              {activeSubscription.fields.hourAmount} hours each month on the{' '}
              {activeSubscription.fields.billingDay}.
            </p>
          )}

          <div className="a-la-carte">
            <button onClick={() => handleSelect('alaCarte')}>
              <p>Get more hours</p>
              <i className="fas fa-angle-right"></i>
            </button>
            <p style={{ color: '#746e6a', fontSize: '0.7rem' }}>
              We get it, sometimes you just need a little more TULA, top up your
              account with a la carte hours now. You’ll be charged the same
              hourly rate as your subscription rate
            </p>
          </div>

          <div className="modify-subscription" onClick={handleManage}>
            <div>
              <p style={{ fontSize: '1.1rem' }}>Modify Subscription</p>
              <i className="fas fa-angle-right"></i>
            </div>
          </div>

          <p
            style={{ color: '#746e6a', fontSize: '0.7rem', marginTop: '1rem' }}
          >
            Change the numbers of hours you get each month
          </p>
        </div>
      ) : (
        <>
          <div className="monthly-subs">
            <button onClick={() => handleSelect('subscription')}>
              <p>Monthly Subscription</p>
              <i className="fas fa-angle-right"></i>
            </button>
            <p className="helper-text">
              Save up to 25% when you subscribe to a package of hours every
              month
            </p>
          </div>

          <div className="a-la-carte">
            <button onClick={() => handleSelect('alaCarte')}>
              <p>A La Carte</p>
              <i className="fas fa-angle-right"></i>
            </button>
            <p className="helper-text">
              Not sure you need a subscription, purchase as many hours as you
              need here
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default HourTypeSelect;
