import React from 'react';

const TodoDetailsForm = ({ todo, setTodo, setStep, canEdit }) => {
  const handleChange = (e) => {
    e.persist();
    setTodo((prevState) => {
      return { ...prevState, description: e.target.value };
    });
  };

  const handleSave = () => {
    setStep('addHome');
  };

  const placeholder =
    'Ex: The gift is for my 40 year old brother .  He is outdoorsy and loves anything sports related.  His favorite color is blue and he’s a size Large.  If you could please include a funny card that would be great.  My budget for the gift is $120';

  return (
    <div className="todo-details">
      <div className="text-area-wrapper">
        <textarea
          name="description"
          placeholder={placeholder}
          defaultValue={todo.description}
          onChange={handleChange}
          disabled={!canEdit}
        />
      </div>

      {canEdit ? (
        <div className="button-container">
          <button className="bg-black" onClick={handleSave}>
            SET DETAILS
          </button>
        </div>
      ) : (
        <>
          <p className="ui-message">Submitted TO-DOs cannot be edited</p>
        </>
      )}
    </div>
  );
};

export default TodoDetailsForm;
