import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import AuthContext from './contexts/AuthContext';
import { TulaContextProvider } from './contexts/TulaContext';
import Router from './Router';

import './App.scss';
import './sass/shared.scss';
import './sass/authStyles.scss';
import './sass/profileStyles.scss';
import './sass/dashStyles.scss';
import './sass/hoursStyles.scss';
import './sass/mobileStyles.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <TulaContextProvider>
        <AuthContext>
          <div className="App">
            <Router />
          </div>
        </AuthContext>
      </TulaContextProvider>
    </Elements>
  );
};

export default App;
