import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { CurrentUserContext } from '../contexts/AuthContext';

import homeScreenBackground from '../assets/homescreen__background.png';
import transBack from '../assets/Tula_Pattern_10x10_LightGoldTransBack.png';
import homeScreenLogo from '../assets/tula__homescreen__logo.png';

const Home = () => {
  const [currentUser] = useContext(CurrentUserContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const history = useHistory();

  useEffect(() => {
    window.addEventListener('resize', (e) =>
      setWindowWidth(e.target.innerWidth),
    );
  }, []);

  useEffect(() => {
    if (currentUser.username) {
      history.push('/my-tula');
    }
  }, [currentUser]);

  return (
    <div className="home">
      <img
        src={windowWidth > 900 ? transBack : homeScreenBackground}
        alt="background"
        id="background-image"
      />
      <section>
        <img src={homeScreenLogo} alt="logo" />
        {currentUser.isLoaded ? (
          currentUser.username ? (
            <Link className="bg-black" to="/my-tula">
              Dash
            </Link>
          ) : (
            <>
              <div className="button-container">
                <Link className="bg-black" to="/auth">
                  Sign In
                </Link>

                <Link className="bg-gold" to="/sign-up">
                  Create Account
                </Link>
              </div>
            </>
          )
        ) : null}
      </section>
    </div>
  );
};

export default Home;
