import React from 'react';

import { getDay } from '../../utils/helpers';

const HoursConfirm = ({ setConfirm, hourPurchase, handleSubmit }) => {
  const handleNext = () => {
    setConfirm(false);
    handleSubmit();
  };

  const subscriptionMessage = () => {
    if (!hourPurchase.promoCode) {
      return `Your card will be charged $${hourPurchase.subscription.price} each
      month on the ${getDay()}`;
    } else {
      const months = hourPurchase.promoCode.coupon.duration_in_months;
      return !months
        ? `Your card will be charged $${hourPurchase.discountedAmount} each
      month on the ${getDay()}`
        : `Your card will be charged the discounted rate of $${hourPurchase.discountedAmount} for ${months}
      months and then $${hourPurchase.subscription.price} each month after`;
    }
  };

  return (
    <>
      <div className="hours-confirm">
        {hourPurchase.type === 'subscription' ? (
          <>
            <p>{hourPurchase.subscription.hours} hours</p>
            <p>Monthly Subscription</p>
            <p>{subscriptionMessage()}</p>

            {hourPurchase.modifying ? (
              <p>
                If you are modifying your subscription to a lower tier, we will
                pro-rate and refund the remaining amount.
              </p>
            ) : null}
          </>
        ) : (
          <>
            <p>{hourPurchase.hours}</p>
            <p>Hours</p>

            <p>
              Your card will be charged $
              {hourPurchase.discountedAmount !== null
                ? hourPurchase.discountedAmount
                : +hourPurchase.hours * hourPurchase.alaCarteRate}
            </p>
          </>
        )}
      </div>
      <div className="button-container">
        <button className="bg-black" onClick={handleNext}>
          COMPLETE PURCHASE
        </button>
      </div>
    </>
  );
};

export default HoursConfirm;
