import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import FormMessage from '../Shared/FormMessage';
import Loader from '../Shared/Loader';

import ImageUpload from './ImageUpload';

const ProfileForm = ({ isNew, updateProfile, profile, loading }) => {
  const { register, errors, formState, getValues, reset } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...profile.fields,
    },
  });
  const { isValid } = formState;
  const [newImage, setNewImage] = useState();

  const handleClick = () => {
    if (!isValid || loading) {
      return;
    }
    const values = getValues();
    values.phone = values.phone.replace(/\D+/g, '');

    if (isNew && !values.termsAgreement) {
      return;
    }

    updateProfile(values, newImage);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ImageUpload profile={profile} setNewImage={setNewImage} />
          <div className="profile-input-blocks">
            <label>First Name</label>
            <input
              name="firstName"
              ref={register({ required: true })}
              autoComplete="off"
            />
          </div>

          <div className="profile-input-blocks">
            <label>Last Name</label>
            <input
              name="lastName"
              ref={register({ required: true })}
              autoComplete="off"
            />
          </div>

          <div className="profile-input-blocks">
            <label>Phone (10 digits)</label>
            <input
              name="phone"
              ref={register({
                required: true,
                // eslint-disable-next-line
                pattern: /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/,
              })}
              autoComplete="off"
            />
          </div>

          <div className="profile-input-blocks">
            <label>Address</label>
            <input
              name="address"
              ref={register({ required: true })}
              autoComplete="off"
            />
          </div>

          {isNew ? (
            <div className="terms-agreement">
              <input type="checkbox" name="termsAgreement" ref={register()} />
              <p>
                By submitting this form, I agree I have read and accept the{' '}
                <a
                  href="https://www.tulabalanced.com/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms of service.
                </a>
              </p>
            </div>
          ) : null}

          {(errors.firstName ||
            errors.lastName ||
            errors.phone ||
            errors.address) && (
            <FormMessage message={'Missing Field!'} closeMessage={reset} />
          )}

          {errors.phone ? (
            <FormMessage
              message={'Invalid Phone Number - Enter a 10 Digit Number'}
              closeMessage={reset}
            />
          ) : null}

          <div className="button-container">
            <button
              className="bg-black"
              disabled={!isValid || loading}
              onClick={handleClick}
            >
              {isNew ? 'Submit' : 'Save'}
            </button>

            {!isNew ? (
              <Link to="/profile" className="" id="cancel-link">
                cancel
              </Link>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default ProfileForm;
