import React from 'react';

const FormMessage = ({ message, closeMessage }) => {
  return (
    <p className="form-alert-text" onClick={() => closeMessage(null)}>
      {message}
    </p>
  );
};

export default FormMessage;
