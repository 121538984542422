import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { allSubscriptionData } from '../../content/subscriptions';
import { TulaContext } from '../../contexts/TulaContext';
import {
  discountAlaCarte,
  discountSubscription,
  validPromoCode,
} from '../../utils/helpers';
import { post } from '../../utils/requests';
import HoursForm from './HoursForm';

const subscriptions = allSubscriptionData[process.env.REACT_APP_AWS_STAGE];

const HourAmountSelect = ({
  setHourPurchase,
  hourPurchase,
  setStep,
  activeSubscription,
}) => {
  const history = useHistory();
  const { state } = useContext(TulaContext);
  const [promoCode, setPromoCode] = useState('');
  const [codeApplied, setCodeApplied] = useState(false);
  const [codeFailed, setCodeFailed] = useState(false);

  useEffect(() => {
    if (hourPurchase.modifying) {
      setHourPurchase((prevState) => {
        return {
          ...prevState,
          subscription: subscriptions.find((sub) => {
            return sub.priceId === activeSubscription.fields.stripePriceId;
          }),
          // todo - dealing with subscription modification
          // promoCode: null,
          // discountedAmount: null,
          // promoMessage: null,
        };
      });
    } else {
      setHourPurchase((prevState) => {
        return {
          ...prevState,
          promoCode: null,
          discountedAmount: null,
          promoMessage: null,
        };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (subscription) => {
    setHourPurchase((prevState) => {
      return { ...prevState, subscription };
    });
  };

  const handleNav = (type) => {
    setHourPurchase((prevState) => {
      return { ...prevState, type };
    });
  };

  const renderSubscriptionOptions = () => {
    return subscriptions.map((option) => {
      return (
        <div
          key={option.hours}
          onClick={() => handleSelect(option)}
          className={
            hourPurchase.subscription &&
            hourPurchase.subscription.hours === option.hours
              ? 'active-subscription-selection'
              : ''
          }
        >
          <p>{option.hours}</p>
          <p>Hours</p>
          <p>Save {option.save}%</p>
        </div>
      );
    });
  };

  const handleCancel = () => {
    setStep('cancelSubscription');
  };

  const handleChange = (e) => {
    setPromoCode(e.target.value);
  };

  const handleApply = async () => {
    const promoRes = await post('/stripe/get-promo', { promo: promoCode });
    const code = promoRes.data.data ? promoRes.data.data[0] : null;

    if (validPromoCode(state.customer, code)) {
      let discountedAmount;
      if (hourPurchase.type === 'alaCarte') {
        discountedAmount = discountAlaCarte(hourPurchase, code);
      } else {
        discountedAmount = discountSubscription(hourPurchase, code);
      }
      const promoMessage = code.coupon.percent_off
        ? `${code.coupon.percent_off}% off`
        : `$${code.coupon.amount_off / 100} off`;

      setHourPurchase((prevState) => {
        return {
          ...prevState,
          promoCode: code,
          discountedAmount,
          promoMessage,
        };
      });

      setCodeApplied(true);
    } else {
      setCodeFailed(true);
    }
  };

  const updateDisabled =
    hourPurchase.modifying &&
    hourPurchase.subscription &&
    +hourPurchase.subscription.hours === +activeSubscription.fields.hourAmount;

  return (
    <>
      <div className="tab-switcher">
        <p
          className={hourPurchase.type === 'subscription' ? 'underline' : ''}
          onClick={() => handleNav('subscription')}
        >
          Subscribe
        </p>
        <p
          className={hourPurchase.type === 'alaCarte' ? 'underline' : ''}
          onClick={() => handleNav('alaCarte')}
        >
          A la Carte
        </p>
      </div>
      {hourPurchase.type === 'subscription' ? (
        <>
          {hourPurchase.modifying ? (
            <>
              <h3 className="general-notice">
                Subscription management coming soon.
              </h3>

              {!activeSubscription.fields.cancelled ? (
                <div className="button-container">
                  <button className="bg-black" onClick={handleCancel}>
                    CANCEL SUBSCRIPTION
                  </button>
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className="grid-container">
                {renderSubscriptionOptions()}
                <div
                  onClick={() => handleSelect('custom')}
                  className={
                    hourPurchase.subscription === 'custom'
                      ? 'active-subscription-selection'
                      : ''
                  }
                >
                  <p>Custom</p>
                </div>
              </div>

              {hourPurchase.subscription ? (
                <>
                  {hourPurchase.subscription === 'custom' ? (
                    <div className="confirmation-details-text">
                      <p>Need more time?</p>
                      <p>
                        Contact us and we’ll work with you to create a custom
                        package.
                      </p>
                    </div>
                  ) : (
                    <div className="confirmation-details-text">
                      <p>
                        {hourPurchase.discountedAmount !== null ? (
                          <>$ {hourPurchase.discountedAmount}</>
                        ) : (
                          <>
                            {hourPurchase.subscription.hours} hours for $
                            {hourPurchase.subscription.price} ($
                            {hourPurchase.subscription.perHour} per hour)
                          </>
                        )}
                      </p>
                      <p>
                        You’ll save {hourPurchase.subscription.save}% off our
                        hourly rate with this subscription tier.
                      </p>
                    </div>
                  )}
                </>
              ) : null}

              <div className="hours-confirm margin-override">
                {codeApplied ? (
                  <>
                    <div className="confirm-promo">
                      <i className="fas fa-check"></i>
                      <p>Promo code applied</p>
                    </div>
                    <p>
                      {promoCode} : {hourPurchase.promoMessage}
                    </p>
                  </>
                ) : (
                  <>
                    <p>Have a promo code or gift card?</p>
                    <div className="confirm-promo">
                      <input
                        id="promo"
                        type="text"
                        onChange={handleChange}
                        value={promoCode}
                        disabled={codeApplied}
                      ></input>
                      <button
                        id="promo-button"
                        onClick={handleApply}
                        disabled={!promoCode}
                      >
                        APPLY
                      </button>
                    </div>

                    {codeFailed ? <p>Invalid Promo Code</p> : null}
                  </>
                )}
              </div>

              <div className="button-container">
                {hourPurchase.subscription === 'custom' ? (
                  <button
                    onClick={() => history.push('/contact-us')}
                    className="bg-gold"
                  >
                    CONTACT US
                  </button>
                ) : (
                  <>
                    {hourPurchase.modifying ? (
                      <button
                        onClick={() => setStep('payment')}
                        disabled={updateDisabled}
                        className="bg-black"
                      >
                        UPDATE
                      </button>
                    ) : (
                      <button
                        onClick={() => setStep('payment')}
                        disabled={!hourPurchase.subscription}
                        className="bg-black"
                      >
                        NEXT STEP: PAYMENT METHOD
                      </button>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <HoursForm
            setHourPurchase={setHourPurchase}
            hourPurchase={hourPurchase}
          />

          <div className="confirmation-details-text">
            <p>
              {hourPurchase.hours} hours for $
              {hourPurchase.discountedAmount !== null
                ? hourPurchase.discountedAmount
                : hourPurchase.alaCarteRate * hourPurchase.hours}{' '}
              {hourPurchase.discountedAmount !== null
                ? null
                : `($ ${hourPurchase.alaCarteRate} per hour)`}
            </p>
          </div>

          <div className="hours-confirm margin-override">
            {codeApplied ? (
              <>
                <div className="confirm-promo">
                  <i className="fas fa-check"></i>
                  <p>Promo code applied</p>
                </div>
                <p>
                  {promoCode} : {hourPurchase.promoMessage}
                </p>
              </>
            ) : (
              <>
                <p>Have a promo code or gift card?</p>
                <div className="confirm-promo">
                  <input
                    id="promo"
                    type="text"
                    onChange={handleChange}
                    value={promoCode}
                    disabled={codeApplied}
                  ></input>
                  <button
                    id="promo-button"
                    onClick={handleApply}
                    disabled={!promoCode}
                  >
                    APPLY
                  </button>
                </div>

                {codeFailed ? <p>Invalid Promo Code</p> : null}
              </>
            )}
          </div>
          <div className="button-container">
            <button
              onClick={() => setStep('payment')}
              disabled={!hourPurchase.hours || hourPurchase.hours < 2}
              className="bg-black"
            >
              NEXT STEP: PAYMENT METHOD
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default HourAmountSelect;
