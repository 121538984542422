const config = {
  //New prod cutover config
  production: {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id:
      'us-east-1:9bdcb2e8-d033-472e-b6a1-78bc66f2b965',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_qULcw1l2s',
    aws_user_pools_web_client_id: '5ljqvjr0bn8tlmmpisetsu8q72',
   oauth: {},
    aws_user_files_s3_bucket:
      'tula-prod-app-images',
    aws_user_files_s3_bucket_region: 'us-east-1',
  },
  //staging env config
  staging: {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id:
      'us-east-1:8a89bc0a-5f2f-4678-a88f-e8566c2acafb',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_wqWtFmzOr',
    aws_user_pools_web_client_id: 'ruh62qmbg5sd1l40k487qi2h7',
   oauth: {},
    aws_user_files_s3_bucket:
      'tuladev-app-images',
    aws_user_files_s3_bucket_region: 'us-east-1',
  },
};

export default config;
