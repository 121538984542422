import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ImageUpload = ({ profile, setNewImage }) => {
  let upload = useRef();
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    if (profile.fields && profile.fields.picture) {
      setImageUrl(profile.fields.picture[0].url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.search === '?upload=true') {
      handleBrowse();
      location.search = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleBrowse = () => {
    upload.value = null;
    upload.click();
  };

  const handleFileSet = async (e) => {
    setImageUrl(URL.createObjectURL(upload.files[0]));
    setNewImage(upload.files[0]);
  };

  return (
    <>
      <input
        type="file"
        name="profileImage"
        accept="image/gif, image/jpeg, image/png"
        multiple={false}
        style={{ display: 'none' }}
        ref={(ref) => (upload = ref)}
        onChange={(e) => handleFileSet(e)}
      />
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          onClick={(e) => handleBrowse()}
          className="tinted"
        />
      ) : (
        <div id="profile-img" onClick={(e) => handleBrowse()}>
          <i className="fas fa-plus"></i>
        </div>
      )}
    </>
  );
};

export default ImageUpload;
