import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
};

export const get = async (endpoint) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });

  try {
    return await instance.get(endpoint);
  } catch (err) {
    throw new Error(err);
  }
};

export const post = async (endpoint, payload) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });
  try {
    return await instance.post(`${endpoint}`, payload);
  } catch (err) {
    return err.response;
  }
};

export const put = async (endpoint, payload) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });
  try {
    return await instance.put(`${endpoint}`, payload);
  } catch (err) {
    throw new Error(err);
  }
};

export const patch = async (endpoint, payload) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });
  try {
    return await instance.patch(`${endpoint}`, payload);
  } catch (err) {
    throw new Error(err);
  }
};

export const remove = async (endpoint) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });
  try {
    return await instance.delete(`${endpoint}`);
  } catch (err) {
    throw new Error(err);
  }
};
