import React, { useContext } from 'react';

import Breadcrumb from '../components/Shared/Breadcrumb';
import { TulaContext } from '../contexts/TulaContext';

const PreferenceForm = () => {
  const { state } = useContext(TulaContext);
  return (
    <>
      <Breadcrumb path={{ returnRoute: '/profile', name: 'Tell Us More' }} />
      <p className="ui-message" style={{ color: 'black' }}>
        We cater our services to fit you to a T!
      </p>
      <iframe
        id="preferencesFrame"
        title="preferences"
        className="airtable-embed"
        src={`https://airtable.com/embed/shrjiEsZ7yOlfW3xJ?prefill_formEmail=${state.customer.fields.email}`}
        frameBorder="0"
        width="100%"
        height="533"
      ></iframe>
    </>
  );
};

export default PreferenceForm;
