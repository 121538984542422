import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { apiDateFormat, pickerDateFormat } from '../../utils/helpers';

const TodoDeadlineForm = ({ todo, setTodo, setStep, canEdit }) => {
  const handleChange = (day, modifiers) => {
    if (!canEdit) {
      return;
    }

    setTodo((prevState) => {
      return {
        ...prevState,
        dueDate: modifiers.disabled ? undefined : apiDateFormat(day),
      };
    });
  };

  const handleSave = () => {
    setStep('addHome');
  };

  const current = new Date();
  const tomorrow = new Date(current.getTime() + 86400000);

  return (
    <div className="todo-details">
      {/* <div className="title-wrapper">
        <i className="fas fa-clipboard-list"></i>
        <p>Set Deadline</p>
      </div> */}
      <DayPicker
        onDayClick={handleChange}
        disabledDays={[{ before: tomorrow }]}
        selectedDays={todo.dueDate ? pickerDateFormat(todo.dueDate) : null}
      />

      {canEdit ? (
        <div className="button-container">
          <button
            className="bg-black"
            onClick={handleSave}
            disabled={!todo.dueDate}
          >
            SET DEADLINE
          </button>
        </div>
      ) : (
        <p className="ui-message">Submitted TO-DOs cannot be edited</p>
      )}
    </div>
  );
};

export default TodoDeadlineForm;
