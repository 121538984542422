import React, { useContext } from 'react';
import {
  Switch,
  Route,
  Redirect,
  // BrowserRouter as Router,
  Router,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import { CurrentUserContext } from './contexts/AuthContext';

import Authenticator from './views/Authenticator';
import Home from './views/Home';
import Profile from './views/Profile';
import RequestInvite from './views/RequestInvite';
import ProfileEdit from './views/ProfileEdit';
import Dash from './views/Dash';
import Todos from './views/Todos';
import Hours from './views/Hours';
import HoursAdd from './views/HoursAdd';
import TodoAdd from './views/TodoAdd';
import QuestionForm from './views/ContactUs';
import PreferenceForm from './views/PreferenceForm';
import TodoSchedule from './views/TodoSchedule';

ReactGA.initialize('G-P5GJEZDXQD');
const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const PrivateRoute = (props) => {
  const [currentUser] = useContext(CurrentUserContext);
  const { component: Component, ...rest } = props;

  if (!currentUser.isLoaded) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser && currentUser.username && currentUser.isLoaded ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
            }}
          />
        );
      }}
    />
  );
};

const NoMatch = ({ location }) => (
  <div>
    <h3>
      404 <code>{location.pathname}</code>
    </h3>
  </div>
);

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/auth" exact component={Authenticator} />
      <Route path="/invite" exact component={Authenticator} />
      <Route path="/sign-up" exact component={Authenticator} />
      <Route path="/confirm" exact component={Authenticator} />
      <Route path="/forgot-confirm" exact component={Authenticator} />
      <Route path="/request-invite" exact component={RequestInvite} />
      <PrivateRoute path="/my-tula" component={Dash} />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/edit-profile" component={ProfileEdit} />
      <PrivateRoute path="/edit-preferences" component={PreferenceForm} />
      <PrivateRoute path="/create-profile" component={ProfileEdit} />
      <PrivateRoute path="/todos" component={Todos} />
      <PrivateRoute path="/new-todo" component={TodoAdd} />
      <PrivateRoute path="/edit-todo/:todoId" component={TodoAdd} />
      <PrivateRoute path="/schedule-todo/:todoId" component={TodoSchedule} />
      <PrivateRoute path="/hours" component={Hours} />
      <PrivateRoute path="/get-hours" component={HoursAdd} />
      <PrivateRoute path="/contact-us" component={QuestionForm} />

      <Route component={NoMatch} />
    </Switch>
  </Router>
);

export default Routes;
