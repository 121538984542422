import { get, post, patch } from './requests';

export const getCustomerData = async (id) => {
  const res = await get(`/airtable/getCustomerData/${id}`);
  return res.data;
};

export const getCustomerByEmail = async (email) => {
  const res = await post(`/airtable/getCustomerByEmail/`, {
    email,
  });
  return res.data;
};

export const getTodoItemsForTodo = async (todoNumber) => {
  const res = await get(`/airtable/getItemsForTodo/${todoNumber}`);
  return res.data;
};

export const getScheduleLinks = async () => {
  const res = await get(`/airtable/getScheduleLinks/`);
  return res.data;
};

export const getRecord = async (table, id) => {
  try {
    const res = await get(`/airtable/${table}/${id}`);

    return res.data;
  } catch (err) {
    console.log('err', err);
  }
};

export const createRecord = async (table, data) => {
  const record = { records: [{ fields: data }] };
  try {
    const res = await post(`/airtable/add/${table}`, record);

    return res;
  } catch (err) {
    console.log('err', err);
  }
};

export const updateRecord = async (table, record) => {
  const data = { records: [record] };
  try {
    const res = await patch(`/airtable/update/${table}`, data);

    return res;
  } catch (err) {
    console.log('err', err);
  }
};
