import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import { CurrentUserContext } from '../../contexts/AuthContext';
import { getCustomerByEmail, getCustomerData } from '../../utils/queries';
import { TulaContext } from '../../contexts/TulaContext';

const SignIn = ({
  updateErrorMessage,
  updateConfirmationMessage,
  signUpEmail,
}) => {
  const history = useHistory();
  const [, setCurrentUser] = useContext(CurrentUserContext);
  const { dispatch } = useContext(TulaContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formValues, setFormValues] = useState({
    username: signUpEmail,
    password: '',
    authCode: '',
  });

  const onChange = (key, value) => {
    updateErrorMessage(null);

    setFormValues((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  const signIn = async () => {
    try {
      let user = await Auth.signIn(
        formValues.username.toLowerCase(),
        formValues.password,
      );
      if (!user.signInUserSession) {
        setShowConfirmation(true);
        updateErrorMessage(null);
      } else {
        let customerRecord;
        if (user.attributes['custom:airtable_user_id'] === '0') {
          customerRecord = await getCustomerByEmail(user.attributes.email);

          await Auth.updateUserAttributes(user, {
            'custom:airtable_user_id': customerRecord.id,
          });

          user = await Auth.currentAuthenticatedUser();

          setCurrentUser({ ...user, ...customerRecord, isLoaded: true });
          dispatch({ type: 'setCustomer', payload: customerRecord });
          history.push('/create-profile');
        } else {
          const customerData = await getCustomerData(
            user.attributes['custom:airtable_user_id'],
          );

          dispatch({ type: 'authIn', payload: { ...customerData } });
          setCurrentUser({ ...user, ...customerData.customer, isLoaded: true });
          history.push('/my-tula');
        }
      }
    } catch (err) {
      console.log('error signing in...: ', err);
      updateErrorMessage(err.message);

      if (err.code === 'UserNotConfirmedException') {
        setShowConfirmation(true);
        updateConfirmationMessage(
          'Username not confirmed, please enter the confirmation code you received in your email.',
        );
      }
    }
  };

  const confirmSignIn = async () => {
    try {
      await Auth.confirmSignUp(
        formValues.username.toLowerCase(),
        formValues.authCode,
      );
      setShowConfirmation(false);
      updateErrorMessage(null);
      updateConfirmationMessage('Successful Confirmation. Please Sign In');
    } catch (err) {
      console.log('error confirming signing in...: ', err);
      updateErrorMessage(err.message);
    }
  };

  return (
    <div className="sign-in-auth">
      {!showConfirmation ? (
        <>
          <p className="hand-font">Hello, Friend</p>
          <div className="sign-in-input-container">
            <div className="input-blocks">
              <i className="far fa-envelope"></i>
              <input
                onChange={(evt) => onChange('username', evt.target.value)}
                placeholder="Email"
                defaultValue={signUpEmail}
              />
            </div>
            <div className="input-blocks">
              <i className="fas fa-key"></i>
              <input
                type="password"
                onChange={(evt) => onChange('password', evt.target.value)}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="button-container">
            <button className="bg-black" onClick={signIn}>
              Sign In
            </button>
          </div>
        </>
      ) : null}
      {showConfirmation ? (
        <>
          <div className="confirm-container">
            <p>
              We sent a confirmation code to your email – go check and return
              here.
            </p>
            <div className="input-blocks">
              <i className="far fa-envelope"></i>
              <input
                placeholder="Email"
                onChange={(evt) => onChange('username', evt.target.value)}
              />
            </div>
            <div className="input-blocks">
              <i className="fas fa-user-shield"></i>
              <input
                onChange={(evt) => onChange('authCode', evt.target.value)}
                placeholder="Confirmation Code"
              />
            </div>
          </div>
          <div className="button-container">
            <button className="bg-black" onClick={confirmSignIn}>
              Confirm Email
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SignIn;
