import React from 'react';

import Header from '../components/Shared/Header';
import TodoHub from '../components/Todos/TodoHub';

const Todos = () => {
  return (
    <>
      <Header />
      <TodoHub />
    </>
  );
};

export default Todos;
