import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../components/Shared/Breadcrumb';
import {
  createRecord,
  getCustomerData,
  getTodoItemsForTodo,
  updateRecord,
} from '../utils/queries';
import { TulaContext } from '../contexts/TulaContext';
import Loader from '../components/Shared/Loader';
import TodoSummaryForm from '../components/Todos/TodoSummaryForm';
import TodoDeadlineForm from '../components/Todos/TodoDeadlineForm';
import TodoDetailsForm from '../components/Todos/TodoDetailsForm';
import TodoSuccess from '../components/Todos/TodoSuccess';
import { useHistory, useParams } from 'react-router-dom';
import { todoNeedsAttention, todoStatusNote } from '../utils/helpers';
import TodoNotice from '../components/Todos/TodoNotice';
import TodoConfirmCancel from '../components/Todos/TodoConfirmCancel';

const TodoAdd = () => {
  const params = useParams();
  const history = useHistory();
  const { state, dispatch } = useContext(TulaContext);
  const [todo, setTodo] = useState({});
  const [assistant, setAssistant] = useState();
  const [editTodo, setEditTodo] = useState();
  const [step, setStep] = useState('addHome');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    const setUpTodo = async () => {
      const activeTodo = state.todos.find((td) => td.id === params.todoId);
      setEditTodo(activeTodo);

      const items = await getTodoItemsForTodo(activeTodo.fields.number);
      setAssistant({
        image: items[0].fields.assistantPicture
          ? items[0].fields.assistantPicture[0].url
          : '',
        name: items[0].fields.assistantFirstName,
      });

      setTodo({
        dueDate: activeTodo.fields.dueDate,
        customerProposedEstimate: activeTodo.fields.hoursAgreement
          ? activeTodo.fields.hoursAgreement
          : activeTodo.fields.customerProposedEstimate,
        name: items[0].fields.name,
        description: items[0].fields.description,
        number: activeTodo.fields.number,
      });
    };

    if (params.todoId) {
      setUpTodo();
    }
  }, []);

  const handleSave = async (submitting) => {
    try {
      setLoading(true);

      if (!editTodo) {
        const newTodo = {
          dueDate: todo.dueDate,
          customerProposedEstimate: +todo.customerProposedEstimate,
          hoursEstimateStatus: +todo.customerProposedEstimate
            ? 'customerProposed'
            : 'estimateRequested',
          listStatus: submitting ? 'submitted' : 'unsubmitted',
          relatedCustomer: [state.customer.id],
        };

        if (submitting) {
          newTodo.dateSubmitted = submitting ? new Date().toISOString() : '';
        }

        const todoRes = await createRecord('todos', newTodo);

        const newTodoItem = {
          relatedList: [todoRes.data[0].id],
          name: todo.name,
          description: todo.description,
          taskStatus: 'unassigned',
        };

        await createRecord('todoItems', newTodoItem);
      } else {
        const todoUpdate = {
          id: editTodo.id,
          fields: {
            dueDate: todo.dueDate,
            customerProposedEstimate: +todo.customerProposedEstimate,
            hoursEstimateStatus: +todo.customerProposedEstimate
              ? 'customerProposed'
              : 'estimateRequested',
            listStatus: submitting ? 'submitted' : 'unsubmitted',
          },
        };
        await updateRecord('todos', todoUpdate);

        const todoItemUpdate = {
          id: editTodo.fields.todoItems[0],
          fields: {
            name: todo.name,
            description: todo.description,
          },
        };
        await updateRecord('todoItems', todoItemUpdate);
      }

      const customerData = await getCustomerData(state.customer.id);
      dispatch({ type: 'updateCustomerData', payload: { ...customerData } });

      setLoading(false);
      setIsSending(submitting);

      if (submitting) {
        setStep('success');
      } else {
        history.push('/my-tula');
      }
    } catch (err) {
      console.log('err writing records', err);
      setLoading(false);
      setError('There was an error with your TO-DO.');
    }
  };
  
  const sendToTula = () => {
    if (!todo.name && !todo.dueDate && !todo.description) {
      setError('Please Fill the required input fields');
    } else if (!todo.name && !todo.dueDate) {
      setError('Please provide the To-do Name and Due date');
    } else if (!todo.name && !todo.description) {
      setError('Please provide the To-do Name and Details');
    } else if (!todo.dueDate && !todo.description) {
      setError('Please provide the To-do Due date and Details');
    }  else if (!todo.name) {
      setError('Please provide To-do Name');
    } else if (!todo.dueDate) {
      setError('Please provide DueDate');
    }else if (!todo.description) {
      setError('Please provide the Details');
    } else if (
      !todo.description &&
      state.customer.fields.hoursBalance < todo.customerProposedEstimate
    ) {
      setError(
        'you do not have sufficient balance please add some more hours and Please provide the details',
      );
    } else if (
      !todo.name &&
      state.customer.fields.hoursBalance < todo.customerProposedEstimate
    ) {
      setError(
        'you do not have sufficient balance please add some more hours and Please provide the To-do Name',
      );
    } else if (
      !todo.dueDate &&
      state.customer.fields.hoursBalance < todo.customerProposedEstimate
    ) {
      setError(
        'you do not have sufficient balance please add some more hours and Please provide the Due Date',
      );
    } else if (
      !todo.name &&
      !todo.dueDate &&
      state.customer.fields.hoursBalance < todo.customerProposedEstimate
    ) {
      setError(
        'you do not have sufficient balance please add some more hours and Please provide the To-do Name and Due date',
      );
    } else if (
      !todo.name &&
      !todo.description &&
      state.customer.fields.hoursBalance < todo.customerProposedEstimate
    ) {
      setError(
        'you do not have sufficient balance please add some more hours and Please provide the To-do Name and Description',
      );
    } else if (
      !todo.name &&
      !todo.description &&
      !todo.dueDate &&
      state.customer.fields.hoursBalance < todo.customerProposedEstimate
    ) {
      setError(
        'you do not have sufficient balance please add some more hours and Please provide the To-do Name and Description',
      );
    } else if (
      state.customer.fields.hoursBalance < todo.customerProposedEstimate
    ) {
      setError('you do not have sufficient balance please add some more hours');
     } else {
      handleSave(true);
    }
  };
  const saveLater = () => {
    if (!todo.name) {
      setError('Please provide To-do Name');
    } else {
      handleSave(false);
    }
  };
  const handleStatusChange = async (status) => {
    setLoading(true);
    const todoUpdate = {
      id: editTodo.id,
      fields: {
        listStatus: status,
      },
    };
    await updateRecord('todos', todoUpdate);
    const customerData = await getCustomerData(state.customer.id);
    dispatch({ type: 'updateCustomerData', payload: { ...customerData } });
    setLoading(false);
    history.push('/my-tula');
  };

   const canEdit = () => {
    return !editTodo || editTodo.fields.listStatus === 'unsubmitted';
  };

  const canDelete = () => {
    return editTodo && editTodo.fields.listStatus === 'unsubmitted';
  };

  const canCancel = () => {
    return editTodo && editTodo.fields.listStatus === 'submitted';
  };

  const handleClose = () => {
    history.push('/my-tula');
  };

  const renderHeader = () => {
    switch (step) {
      case 'deadline': {
        return (
          <div className="breadcrumb">
            <i
              className="fas fa-chevron-left"
              onClick={() => setStep('addHome')}
            ></i>

            <p>SET DEADLINE</p>
          </div>
        );
      }
      case 'details': {
        return (
          <div className="breadcrumb">
            <i
              className="fas fa-chevron-left"
              onClick={() => setStep('addHome')}
            ></i>

            <p>ADD DETAILS</p>
          </div>
        );
      }
      case 'success': {
        return (
          <div className="breadcrumb">
            <p id="title">
              <i className="fas fa-times" onClick={handleClose}></i>
            </p>
          </div>
        );
      }
      case 'confirmCancel': {
        return (
          <div className="breadcrumb">
            <i
              className="fas fa-chevron-left"
              onClick={() => setStep('addHome')}
            ></i>

            <p>CANCEL TODO</p>
          </div>
        );
      }
      default: {
        return (
          <Breadcrumb
            path={{
              returnRoute: '/my-tula',
              name: params.todoId ? 'Edit TO-DO' : 'Add TO-DO',
            }}
            canCancel={canCancel()}
            canDelete={canDelete()}
            cancelTodo={setStep}
          />
        );
      }
    }
  };

  return (
    <>
      {renderHeader()}
      <div className="todo-add">
        {step === 'addHome' ? (
          <TodoSummaryForm
            todo={todo}
            setTodo={setTodo}
            loading={loading}
            setStep={setStep}
            canEdit={canEdit()}
            editTodo={editTodo}
          />
        ) : null}

        {step === 'deadline' ? (
          <TodoDeadlineForm
            todo={todo}
            setTodo={setTodo}
            setStep={setStep}
            canEdit={canEdit()}
          />
        ) : null}

        {step === 'details' ? (
          <TodoDetailsForm
            todo={todo}
            setTodo={setTodo}
            setStep={setStep}
            canEdit={canEdit()}
          />
        ) : null}

        {step === 'success' ? <TodoSuccess isSending={isSending} /> : null}

        {step === 'confirmCancel' ? (
          <TodoConfirmCancel
            loading={loading}
            cancelTodo={handleStatusChange}
            todoId={editTodo.id}
            setStep={setStep}
            canCancel={canCancel()}
            canDelete={canDelete()}
          />
        ) : null}

        {step === 'addHome' ? (
          <>
            {editTodo && todoNeedsAttention(editTodo.fields) ? (
              <TodoNotice todo={editTodo} setStep={setStep} />
            ) : null}

            {loading ? (
              <Loader />
            ) : (
              <>
                {editTodo &&
                editTodo.fields.listStatus === 'inProgress' &&
                assistant ? (
                  <div className="assistant-display">
                    {assistant.image ? (
                      <img
                        src={assistant.image}
                        alt="avatar"
                        className="assistant-image"
                      />
                    ) : null}
                    <p>
                      Your TO-DO is assigned to{' '}
                      <strong>{assistant.name}</strong>
                    </p>
                  </div>
                ) : null}

                {canEdit() ? (
                  <>
                    <p className="save-notice">
                      Still finalizing details? Save now so you can edit as much
                      as you like before submitting to TULA. Once you’ve sent
                      your task, edits are a bit tricky!
                    </p>
                    <div className="button-container">
                      <button onClick={saveLater} className="bg-gold">
                        SAVE FOR LATER
                      </button>
                      <button onClick={sendToTula} className="bg-black">
                        SEND TO TULA
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="ui-message">
                      {todoStatusNote(editTodo.fields.listStatus)}
                    </p>
                  </>
                )}

                {}
              </>
            )}
          </>
        ) : null}
      </div>
      {error ? <p className="form-alert-text">{error}</p> : null}
    </>
  );
};

export default TodoAdd;
