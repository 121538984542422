import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { TulaContext } from '../../contexts/TulaContext';
import { todoNeedsAttention, todoStatusMatch } from '../../utils/helpers';

const TodoList = () => {
  const { state } = useContext(TulaContext);
  const [status, setStatus] = useState(
    state.todoNotices.length ? 'needsAttention' : 'saved',
  );
  const [filteredTodos, setFilteredTodos] = useState([]);

  useEffect(() => {
    setFilteredTodos(
      state.todos.filter((todo) => todoStatusMatch(todo.fields, status)),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleChange = (e) => {
    setStatus(e.target.value);
  };

  const renderTodos = () => {
    return filteredTodos.map((todo, i) => {
      return (
        <div key={todo.id}>
          <Link to={`/edit-todo/${todo.id}`}>
            <p>{todo.fields.itemNames[0]}</p>
            {todoNeedsAttention(todo.fields) ? (
              <span className="todo-notice-bubble">1</span>
            ) : (
              <i className="fas fa-chevron-right"></i>
            )}
          </Link>
        </div>
      );
    });
  };
  return (
    <>
      <select value={status} onChange={handleChange}>
        {state.todoNotices.length ? (
          <option value="needsAttention">Needs Attention</option>
        ) : null}
        <option value="saved">Saved</option>
        <option value="pending">Pending</option>
        <option value="inProgress">In Progress</option>
        <option value="completed">Completed</option>
      </select>

      <div className="todos">
        {!filteredTodos.length ? (
          <p id="helper-text">
            You don&apos;t have any{' '}
            {status === 'inProgress' ? 'in progress' : status} TO-DOs
          </p>
        ) : (
          renderTodos()
        )}
      </div>
    </>
  );
};

export default TodoList;
