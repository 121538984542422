import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { TulaContext } from '../../contexts/TulaContext';
import { getCustomerData, updateRecord } from '../../utils/queries';
import Loader from '../Shared/Loader';

const TodoNotice = ({ todo, setStep }) => {
  const { state, dispatch } = useContext(TulaContext);
  const [loading, setLoading] = useState();

  const handleApprove = () => {
    const todoUpdate = {
      id: todo.id,
      fields: {
        hoursEstimateStatus: 'accepted',
        hoursAgreement: todo.fields.tulaProposedEstimate,
      },
    };

    updateTodo(todoUpdate);
  };

  const updateTodo = async (update) => {
    setLoading(true);

    await updateRecord('todos', update);

    const customerData = await getCustomerData(state.customer.id);
    dispatch({ type: 'updateCustomerData', payload: { ...customerData } });
    setLoading(false);

    setStep('success');
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {todo.fields.hoursEstimateStatus === 'needsApproval' ? (
            <div className="needs-approval">
              <p>
                A new hours estimate requires your approval for us to complete
                this TO-DO
              </p>
              <p>
                Original Estimate: {todo.fields.customerProposedEstimate || 0}{' '}
                hours
              </p>
              <p>Proposed Estimate: {todo.fields.tulaProposedEstimate} hours</p>
              <div className="button-container">
                <button className="bg-black" onClick={handleApprove}>
                  Approve
                </button>
              </div>
            </div>
          ) : null}

          {todo.fields.insufficientBalance < 0 ? (
            <div className="insufficient-balance">
              <p>
                Oops, while we can’t wait to help you out, you’ll need
                additional hours for TULA to complete this request.{' '}
              </p>
              <p>Estimated: {todo.fields.hoursAgreement} hours</p>
              <p>Current Balance: {state.customer.fields.hoursBalance} hours</p>
              <div className="button-container">
                <Link className="bg-black" to="/get-hours">
                  GET HOURS
                </Link>
              </div>
            </div>
          ) : null}

          {todo.fields.needsSchedule && !todo.fields.scheduleComplete ? (
            <div className="insufficient-balance">
              <p>Please schedule a time for us to complete your TO-DO.</p>
              <div className="button-container">
                <Link className="bg-black" to={`/schedule-todo/${todo.id}`}>
                  SCHEDULE APPOINTMENT
                </Link>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default TodoNotice;
