// import { formatDistance, addDays } from 'date-fns';
import { formatDistance } from 'date-fns';

import config from '../config';

export const getDay = () => {
  const dt = new Date();
  return (
    dt.getDate() +
    (dt.getDate() % 10 === 1
      ? 'st'
      : dt.getDate() % 10 === 2 && dt.getDate() !== 12
      ? 'nd'
      : dt.getDate() % 10 === 3 && dt.getDate() !== 13
      ? 'rd'
      : dt.getDate() % 10 === 1 && dt.getDate() !== 1
      ? 'rd'
      : 'th')
  );
};

export const apiDateFormat = (date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const pickerDateFormat = (date) => {
  const yesterday = new Date(date.replace(/-/gi, '/'));
  return new Date(yesterday.getTime() - yesterday.getTimezoneOffset() * -60000);
};

export const formatDueDate = (date) => {
  const dueDate = new Date(date.replace(/-/gi, '/'));
  dueDate.setDate(dueDate.getDate() + 1);
  return formatDistance(new Date(), dueDate);
};

export const todoStatusMatch = (todo, selectedStatus) => {
  switch (selectedStatus) {
    case 'needsAttention': {
      return todoNeedsAttention(todo);
    }
    case 'saved': {
      return todo.listStatus === 'unsubmitted';
    }
    case 'pending': {
      return !todoNeedsAttention(todo) && todo.listStatus === 'submitted';
    }
    case 'inProgress': {
      return todo.listStatus === 'inProgress';
    }
    case 'completed': {
      return todo.listStatus === 'completed';
    }
    default: {
      return false;
    }
  }
};

export const todoStatusNote = (todoStatus) => {
  switch (todoStatus) {
    case 'estimateApproved': {
      return 'We are assigning an assistant. Keep an eye out for updates.';
    }
    default: {
      return null;
    }
  }
};

export const todoNeedsAttention = (todo) => {
  const submittedOrInProgress =
    todo.listStatus === 'submitted' || todo.listStatus === 'inProgress';
  const needsApproval =
    submittedOrInProgress && todo.hoursEstimateStatus === 'needsApproval';

  const needsHours = submittedOrInProgress && +todo.insufficientBalance < 0;

  const needsSchedule = todo.needsSchedule && !todo.scheduleComplete;

  return needsApproval || needsHours || needsSchedule;
};

export const s3Url = (fileName) => {
  return `https://${
    config[process.env.REACT_APP_AWS_STAGE].aws_user_files_s3_bucket
  }.s3.amazonaws.com/public/${fileName}`;
};

export const getProfilePictureUrl = (profile, size) => {
  let url;
  if (profile && profile.fields.picture) {
    url = profile.fields.picture[0].url;

    if (profile.fields.picture[0].thumbnails) {
      url = profile.fields.picture[0].thumbnails[size].url;
    }
  }

  return url;
};

export const validPromoCode = (customer, code) => {
  if (!code || !code.active) {
    return false;
  }
  if (code.customer) {
    return code.customer === customer.fields.stripeId;
  } else {
    return true;
  }
};

export const discountAlaCarte = (hourPurchase, code) => {
  const base = hourPurchase.alaCarteRate * hourPurchase.hours;

  const discount = code.coupon.percent_off
    ? base - base * (code.coupon.percent_off / 100)
    : base - code.coupon.amount_off / 100;

  return discount < 0 ? 0 : discount;
};

export const discountSubscription = (hourPurchase, code) => {
  const base = +hourPurchase.subscription.price;

  const discount = code.coupon.percent_off
    ? base - base * (code.coupon.percent_off / 100)
    : base - code.coupon.amount_off / 100;

  return discount < 0 ? 0 : discount;
};
