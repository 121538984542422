import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { TulaContext } from '../contexts/TulaContext';
import Loader from '../components/Shared/Loader';

import { getProfilePictureUrl } from '../utils/helpers';

const Profile = () => {
  const history = useHistory();
  const [profile, setProfile] = useState();
  const { state } = useContext(TulaContext);

  useEffect(() => {
    setProfile(state.profile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    history.push('my-tula');
  };

  const handleImageClick = () => {
    if (getProfilePictureUrl(profile, 'large')) {
      return;
    }

    history.push('/edit-profile?upload=true');
  };

  return (
    <>
      {!profile ? (
        <Loader />
      ) : (
        <div className="profile">
          <p id="title">
            PROFILE <i className="fas fa-times" onClick={handleClose}></i>
          </p>

          {getProfilePictureUrl(profile, 'large') ? (
            <img
              src={getProfilePictureUrl(profile, 'large')}
              alt="avatar"
              onClick={handleImageClick}
            />
          ) : (
            <div id="profile-img">
              <i className="fas fa-plus"></i>
            </div>
          )}

          <div className="profile-input-blocks">
            <p>First Name</p>
            <input
              type="text"
              id="first_name"
              defaultValue={profile.fields.firstName}
              disabled
            />
          </div>
          <div className="profile-input-blocks">
            <p>Last Name</p>
            <input
              type="text"
              id="last_name"
              defaultValue={profile.fields.lastName}
              disabled
            />
          </div>
          <div className="profile-input-blocks">
            <p>Phone number</p>
            <input
              type="text"
              id="phone_number"
              defaultValue={profile.fields.phone}
              disabled
            />
          </div>
          <div className="profile-input-blocks">
            <p>Address</p>
            <input
              type="text"
              id="address"
              defaultValue={profile.fields.address}
              disabled
            />
          </div>
          <div className="button-container">
            <Link className="bg-gold" to="/edit-preferences">
              Tell us more
            </Link>

            <Link className="bg-black" to="/edit-profile">
              Edit Profile
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
