import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { TulaContext } from '../../contexts/TulaContext';
import { apiDateFormat } from '../../utils/helpers';
import { getCustomerData, updateRecord } from '../../utils/queries';
import { post } from '../../utils/requests';
import Loader from '../Shared/Loader';

const CancelSubscription = ({ activeSubscription, setStep }) => {
  const { state, dispatch } = useContext(TulaContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const handleCancel = async () => {
    try {
      setLoading(true);

      const cancelRes = await post(`/stripe/cancel-subscription`, {
        subscriptionId: activeSubscription.fields.stripeSubscriptionId,
      });

      const subscriptionUpdate = {
        id: activeSubscription.id,
        fields: {
          cancelDate: apiDateFormat(new Date()),
          cancelled: true,
          cancelCurrentPeriodEnd: cancelRes.data.current_period_end,
        },
      };

      await updateRecord('subscriptions', subscriptionUpdate);

      const customerData = await getCustomerData(state.customer.id);

      dispatch({ type: 'updateCustomerData', payload: { ...customerData } });
      setLoading(false);

      setSuccess('Your subscription has been cancelled');
    } catch (err) {
      console.log('err writing records', err);

      setLoading(false);
      setError('There was an error with your purchase.');
    }
  };

  return (
    <div className="cancel-subscription">
      <p style={{ textAlign: 'center', fontSize: '1.1rem' }}>
        Cancel Subscription
      </p>

      {!success ? (
        <>
          <div className="cancel-subscription-text">
            <p>
              Your current subscription is for{' '}
              {activeSubscription.fields.hourAmount} hours. Tula bills $
              {+activeSubscription.fields.monthlyBillAmount} ($
              {activeSubscription.fields.ratePerHour}/hr) on the{' '}
              {activeSubscription.fields.billingDay} of the month.
            </p>

            <p>
              You will keep your current hours balance until the{' '}
              {activeSubscription.fields.billingDay} when your subscription will
              end.
            </p>
            <p>ARE YOU SURE?</p>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div className="button-container">
              <button className="bg-black" onClick={handleCancel}>
                YES, CANCEL
              </button>
              <button
                className="bg-gold"
                onClick={() => setStep('amountSelect')}
              >
                NEVERMIND
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          <p className="ui-message">{success}</p>
          <div className="button-container">
            <Link className="bg-gold" to="/my-tula">
              Dash
            </Link>
          </div>
        </>
      )}

      {error ? <p>{error}</p> : null}
    </div>
  );
};

export default CancelSubscription;
