export const allSubscriptionData = {
  production: [
    {
      hours: '4',
      save: '12.5',
      perHour: '43.75',
      price: '175',
      priceId: 'price_1He4TGBlHLG8FMMdoKYzGC3x',
      singlePriceId: 'price_1He4TGBlHLG8FMMddJYOV1QT',
    },
    {
      hours: '6',
      save: '17',
      perHour: '41.66',
      price: '250',
      priceId: 'price_1He4T8BlHLG8FMMdZDrvRCqH',
      singlePriceId: 'price_1He4T8BlHLG8FMMdfLboz50S',
    },
    {
      hours: '8',
      save: '25',
      perHour: '37.50',
      price: '300',
      priceId: 'price_1He4SwBlHLG8FMMdr3QwP1hK',
      singlePriceId: 'price_1He4SwBlHLG8FMMdPDnMrLpf',
    },
  ],
  staging: [
    {
      hours: '4',
      save: '12.5',
      perHour: '43.75',
      price: '175',
      priceId: 'price_1He2TJBlHLG8FMMdI8qtRubG',
      singlePriceId: 'price_1He2TJBlHLG8FMMdLJ0OpsnD',
    },
    {
      hours: '6',
      save: '17',
      perHour: '41.66',
      price: '250',
      priceId: 'price_1He2UQBlHLG8FMMdBTN6143R',
      singlePriceId: 'price_1He2URBlHLG8FMMd0cew88Z9',
    },
    {
      hours: '8',
      save: '25',
      perHour: '37.50',
      price: '300',
      priceId: 'price_1He2cFBlHLG8FMMdaXDhgJsT',
      singlePriceId: 'price_1He2cFBlHLG8FMMdFEkmguKs',
    },
  ],
};

export const allSingleHourPrices = {
  production: {
    singlePriceId: 'price_1He4T3BlHLG8FMMdk4FgD5Wd',
  },
  staging: {
    singlePriceId: 'price_1He2XyBlHLG8FMMdHAlrHF0I',
  },
};
