import React from 'react';

import { formatDueDate } from '../../utils/helpers';

const TodoSummaryForm = ({
  todo,
  setTodo,
  loading,
  setStep,
  itemAdd,
  canEdit,
}) => {
  const handleChange = (e) => {
    e.persist();
    setTodo((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  return (
    <>
      <section>
        <input
          name="name"
          placeholder="TO-DO name"
          defaultValue={todo.name}
          disabled={loading || !canEdit}
          onChange={handleChange}
          id="title-input"
        />
      </section>
      {!itemAdd ? (
        <>
          <section onClick={() => setStep('deadline')}>
            <div>
              <i className="fas fa-clipboard-list"></i>
              Due in {todo.dueDate ? formatDueDate(todo.dueDate) : ''}
            </div>{' '}
            <i className="fas fa-chevron-right"></i>
          </section>

          <section id="estimate-section">
            <div id="estimate-section-subs">
              <div>
                <i className="far fa-clock"></i>
                <input
                  id="hours-input"
                  name="customerProposedEstimate"
                  type="number"
                  placeholder="How long this should take?"
                  defaultValue={todo.customerProposedEstimate}
                  disabled={loading || !canEdit}
                  onChange={handleChange}
                />
                {todo.customerProposedEstimate ? 'hours' : null}
              </div>
            </div>
            {canEdit ? <p>*Leave blank for TULA to estimate</p> : null}
          </section>

          <section onClick={() => setStep('details')}>
            <div>
              <i className="fas fa-comment-alt"></i>
              {todo.description
                ? `${todo.description.substring(0, 25)}...`
                : 'Add details (optional)'}
            </div>
            <i className="fas fa-chevron-right"></i>
          </section>
        </>
      ) : null}
    </>
  );
};

export default TodoSummaryForm;
