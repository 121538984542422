import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { CurrentUserContext } from '../../contexts/AuthContext';
import { TulaContext } from '../../contexts/TulaContext';

import hamBurgerMenu from '../../assets/hamburger__menu__icon.png';
import hamBurgerClose from '../../assets/hamburger__close__icon.png';
import tulaHeaderLogo from '../../assets/tula__header__logo.png';
import { getProfilePictureUrl } from '../../utils/helpers';

const Header = () => {
  const history = useHistory();
  const [currentUser] = useContext(CurrentUserContext);
  const { state, dispatch } = useContext(TulaContext);
  const [isMenuOpen, setMenuStatus] = useState(false);

  const signOut = async () => {
    try {
      await Auth.signOut();

      history.push('/auth');
      dispatch({ type: 'authOut' });
    } catch (err) {
      console.log('error signing out...');
    }
  };

  useEffect(() => {
    const hamburger = document.querySelector('.hamburger');
    const navLinks = document.querySelector('.nav-links');
    const links = document.querySelectorAll('.nav-links li');

    hamburger.addEventListener('click', () => {
      setMenuStatus((prevState) => !prevState);
      navLinks.classList.toggle('open');
      links.forEach((link) => {
        link.classList.toggle('fade');
      });
    });
  }, []);

  return (
    <div className="header">
      <Link to="/">
        <img src={tulaHeaderLogo} alt="logo" />
      </Link>
      {getProfilePictureUrl(state.profile, 'small') ? (
        <>
          <img
            src={getProfilePictureUrl(state.profile, 'small')}
            alt="avatar"
            id="avatar"
          />
          {state.todoNotices.length ? (
            <span>{state.todoNotices.length}</span>
          ) : null}
        </>
      ) : (
        <>
          <i className="fas fa-user-circle" id="avatar"></i>
          {state.todoNotices.length ? (
            <span>{state.todoNotices.length}</span>
          ) : null}
        </>
      )}

      <nav className="hamburger">
        {isMenuOpen ? (
          <img src={hamBurgerClose} alt="menu" />
        ) : (
          <img src={hamBurgerMenu} alt="close" />
        )}
      </nav>

      {currentUser.username ? (
        <ul className="nav-links">
          <li>
            <Link to="/my-tula">Dash</Link>
          </li>
          <li>
            <Link to="/todos">TO-DOs</Link>
          </li>
          <li>
            <Link to="/profile">Settings</Link>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tulabalanced.com/how-it-works#faq"
            >
              FAQs
            </a>
          </li>
          <li>
            <p onClick={signOut}>Sign Out</p>
          </li>
        </ul>
      ) : (
        <Link to="/auth">
          <p>Sign In</p>
        </Link>
      )}
    </div>
  );
};

export default Header;
