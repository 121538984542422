import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ path, canCancel, canDelete, cancelTodo }) => {
  return (
    <div className="breadcrumb">
      <Link to={path.returnRoute}>
        <i className="fas fa-chevron-left"></i>
      </Link>
      <p>{path.name}</p>
      {canCancel || canDelete ? (
        <span>
          <i
            className="fas fa-trash"
            onClick={() => cancelTodo('confirmCancel')}
          ></i>
        </span>
      ) : null}
    </div>
  );
};

export default Breadcrumb;
